import React from "react";
import {Card, CardActions, CardContent} from "@material-ui/core";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Toast, ToastBody, Button,} from 'reactstrap';

import SignatureCanvas from 'react-signature-canvas';

/**
 *  @property {Forms_Completion_Object} state
 *  @property {object} model
 *  @property {Forms_Completion_Properties} model.properties
 *  @property {Forms_Completion_Is} model.is
 *  @property {Forms_Completion_Has} model.has
 */

const setSigPadWidth = () => {
	let scrollbarWidth = 17; //Based on Google Chrome, can detect different browsers if necessary
	let viewportWidth = window.innerWidth - scrollbarWidth;
	const styledPaddingAndPositioning = ((15+14+40)*2)
	//15px: .container-fluid | 14px: .MuiCardContent_root |
	//40px: .signature (this is left/right positioning, and includes a second .MuiCardContent_root)

	let breakpointXsmSmCalc = () => viewportWidth - styledPaddingAndPositioning;
	let breakpointMdCalc = () => viewportWidth*0.7 - styledPaddingAndPositioning;
	let breakpointLgXlgCalc = () => (viewportWidth*0.7 - 15*2 - 14*2)*0.7 - 40*2;
	return window.innerWidth < 769 ? breakpointXsmSmCalc()
		: window.innerWidth < 992 ? breakpointMdCalc()
			: breakpointLgXlgCalc();
}

export default class MandatoryNetworkInspectionWaiver extends Framework.Components.Object{
	constructor(props) {
		super(props, Types.Forms_Network_Inspection._name );
	}

	componentDidMount(){
		if(this.props.passedObject){
			this.setState(this.props.passedObject);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		/** @type {Forms_Completion_Object} object */
		let object = this.state;

		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name);
		}
	}

	/** @type Object */
	sigPad1 = null;
	clear = (sigPad) => {
		this[sigPad].clear();
	};

	getSigPad1Ref = (ref) => {
		if (!ref) return;
		this.sigPad1 = ref;
	}

	clearSigPad1Ref = () => this.clear('sigPad1')


	loadSelfAfter(){
		this.setState({warranty:false})
	}

	async handleSubmit(event, suppressToast = false) {
		if ( this.sigPad1.getCanvas() !== null) {
			this.state.signature = this.sigPad1.getCanvas().toDataURL('image/png');
		}
		this.state.name = this.state.name.trim();

		let posting = false;
		if(this.state.name !== '' && this.state.fresh_desk !== ''){
			posting = true;
		}
		await super.handleSubmit();
		if(posting){
			this.props.passedObject.post ? window.location.href = this.props.passedObject.post : ''
		}
	}

	render(){
		/** @type {Forms_Completion_Properties} object */
		let {properties} = this.model;
		/** @type {Forms_Completion_Object} object */
		let object = this.state;
		let isNew = !this.state.pkey || this.state.pkey === '0';
		let disabled = object.signature && (this.props.id || this.props.id==="0")

		let sigCanvasDims = {width: setSigPadWidth(), height:300 };
		return(
			<Card>
				<CardContent>
					<Toast style={{maxWidth:"100%"}} className={"bg-warning"}>
						<ToastBody className={"text-center"}>
							<h5>WARNING: PLEASE READ CAREFULLY BEFORE SIGNING!</h5>
							<p>Karls Technology Mandatory Network Inspection Waiver</p>
						</ToastBody>
					</Toast>
					{/*#region Information*/}
					<h4 className="section-title mdl-typography--text-center">
						Mandatory Network Inspection Waiver
					</h4>

					<div className={"row"} >
						<div className={"col"}>
							<p>
								1. Refusal of Service: This is an acknowledgement that I am rejecting the recommended best industry practice of having my network inspected for vulnerabilities and reliability while having my computer serviced.
							</p>
							<p>
								2. Release of Liability: I acknowledge that Karls Technology does not guarantee the security nor stability of my network and I unconditionally release and forever discharge Karls Technology and its agents, staff, representatives, managers, subsidiaries, assigns, and affiliates from any and all claims, causes of action due to network instability and vulnerbilities.
							</p>
							<p>
								3. Waiver of Warranty: By refusing one of the mandatory diagnostics of Karls Technology's four point inspection process, I waive the right to having a warranty for services performed by Karls Technology.
							</p>
						</div>
					</div>

					{/*#endregion*/}
					<fieldset disabled={!!disabled}>
						<h4 className="section-title">Information Needed</h4>
						<div className={"row"}>
							<div className={"col-md"}>
								<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Name"}
										value={object.name}
										error={object.name_error}
										property={properties.name}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>

							<div className={"col-md"}>
								<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Freshdesk Number"}
										value={object.fresh_desk}
										error={object.fresh_desk_error}
										property={properties.fresh_desk}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>
						</div>

						<h4 className="section-title"> Signature</h4>
						<div>
							<div className={'row signature-row'} >
								<div className={'col-12'}>
									<div className="mdl-typography--text-center">
										<Card>
											<CardContent className="OnePage positionRelative">
												<span className={'signature'}/>
												{object.signature ? <img src={object.signature} alt={'Signature'}/> :
													<SignatureCanvas penColor='black' canvasProps={Object.assign({className: 'sigCanvas'}, sigCanvasDims)}
													                 ref={this.getSigPad1Ref}/>}
											</CardContent>
											{!object.signature?<CardActions className="OnePage signatureClearButtonContainer">
												<Button color="secondary" onClick={this.clearSigPad1Ref}>Clear</Button>
											</CardActions>:null}
										</Card>
									</div>
								</div>
							</div>
						</div>
					</fieldset>

				</CardContent>
				<CardActions>
					<Button color="primary" onClick={this.handleSubmit}>
						{isNew ? 'Submit' : 'Save'}
					</Button>
				</CardActions>
			</Card>
		)
	}
}
