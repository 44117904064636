import AllWaivers from "components/Collections/AllWaivers";
import CompletionCertificates from "components/Collections/CompletionCertificates";
import ComputerWaivers from "components/Collections/ComputerWaivers";
import MandatoryAntiVirusInspectionWaivers from "components/Collections/MandatoryAntiVirusInspectionWaivers";
import MandatoryDataBackupWaivers from "components/Collections/MandatoryDataBackupWaivers";
import MandatoryNetworkInspectionWaivers from "components/Collections/MandatoryNetworkInspectionWaivers";
import MandatoryHardDriveInspectionWaivers from "components/Collections/MandatoryHardDriveInspectionWaivers";
import RefundAgreements from "components/Collections/RefundAgreements";

const Collections = {
	AllWaivers: AllWaivers,
	CompletionCertificates: CompletionCertificates,
	ComputerWaivers: ComputerWaivers,
	MandatoryAntiVirusInspectionWaivers: MandatoryAntiVirusInspectionWaivers,
	MandatoryDataBackupWaivers: MandatoryDataBackupWaivers,
	MandatoryNetworkInspectionWaivers: MandatoryNetworkInspectionWaivers,
	MandatoryHardDriveInspectionWaivers: MandatoryHardDriveInspectionWaivers,
	RefundAgreements: RefundAgreements,
};

export default Collections;
