import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Card, CardContent} from "@material-ui/core";
import APPCONFIG from "constants/Config";
import moment from "moment";

export default class MandatoryNetworkInspectionWaivers extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Forms_Network_Inspection._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;

		const columns = [
			{
				Header: "Name",
				id: "name",
				accessor: obj => (obj.name)
			},
			{
				Header: "Freshdesk Number",
				id: "fresh_desk",
				accessor: obj => (obj.fresh_desk)
			},
			{
				Header: "Date Signed",
				id: "created_date",
				accessor: obj => (moment(obj.created_date).format('M/D/Y'))
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 70,
				Cell: props => {
					return (
						<span>
							<a href={APPCONFIG.settings.apiURL + '/' + APPCONFIG.settings.apiVersion + "/forms/network_inspection/print/" + props.original.pkey} target="_blank">
								<Button color="primary" size={"sm"} block={true}>
									<i className={"fa fa-file-pdf-o"}/> PDF
								</Button>
							</a>
						</span>
					);
				}
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 70,
				Cell: props => {
					return (
						<span>
							<Link to={"/mandatory_network_inspection_waiver/" + props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									View
								</Button>
							</Link>
						</span>
					);
				}
			},
		];

		return (
			<Card>
				<CardContent>
					<div className="box-body">
						<div className="table-bar">
							<div className="d-flex">
								{this.renderSearch('AntiVirus Inspections')}
								{this.renderHeaderRight("AntiVirus Inspections",columns)}
							</div>
						</div>
						<ReactTable
							key={this.state.key}
							columns={columns}
							manual // Forces table not to paginate or sort automatically, so we can handle it server-side
							data={data}
							pages={pages} // Display the total number of pages
							loading={loading} // Display the loading overlay when we need it
							loadingText="Requesting Data"
							onFetchData={this.fetchData} // Request new data when things change
							expanderDefaults={{
								sortable: true,
								resizable: true,
								filterable: false,
								width: undefined
							}}
							onExpandedChange={this.onExpandedChange}
							defaultSorted={[
								{
									id: this.model.sort,
									desc: this.model.order === 'desc'
								}
							]}
							showPagination= {true}
							showPaginationTop= {false}
							showPaginationBottom= {true}
							showPageSizeOptions= {true}
							defaultPageSize={limit}
							className="-striped -highlight"
						/>
					</div>

					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				</CardContent>
			</Card>
		)
	}
}
