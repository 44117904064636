import React from 'react';
import {Objects} from "Components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "../../../../../constants/Config";

export default class Mandatory_Data_Backup_Waiver extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.MandatoryDataBackupWaiver
						id={this.props.match.params.id}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={'/mandatory_data_backup_waivers/form'}
						noDelete = {false}
					/>
				</Framework.Errors.General>
			</div>
		)
	}
};
