import React from "react";
import {Card, CardActions, CardContent} from "@material-ui/core";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Toast, ToastBody, Button,} from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';

/**
 *  @property {Forms_Results_Object} state
 *  @property {object} model
 *  @property {Forms_Results_Properties} model.properties
 *  @property {Forms_Results_Is} model.is
 *  @property {Forms_Results_Has} model.has
 */

export default class ComputerRepairWaiver extends Framework.Components.Object{
	constructor(props) {
		super(props, Types.Forms_Results._name);
		this.state.sigWidth = null;
		window.addEventListener("orientationchange", this.orientationChange);
	}
	signatureHold = null;

	componentDidMount(prevProps, prevState, snapshot){
		super.componentDidMount(prevProps, prevState, snapshot)
		this.setSigPadWidth();
		if(this.props.passedObject){
			this.setState(this.props.passedObject);
		}
	}

	orientationChange = () => {
		this.signatureHold = this.sigPad1.getCanvas().toDataURL('image/png');
		this.componentDidUpdate(this.props, this.state, null, true)
	}

	componentDidUpdate(prevProps, prevState, snapshot, resizeImage = false) {

		super.componentDidUpdate(prevProps, prevState, snapshot);

		/** @type {Forms_Results_Object} object */
		let object = this.state;

		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name);
		}

		if( resizeImage ) {
			setTimeout(this.setSigPadWidth, 250);
		}

		if( this.state.sigWidth !== prevState.sigWidth ) {
			if(this.sigPad1 && this.signatureHold) {

				this.sigPad1.fromDataURL(this.signatureHold, {width: this.state.sigWidth, height: 300});
				this.signatureHold = null;
			}
		}

	}

	setSigPadWidth = () => {
		let scrollbarWidth = 17; //Based on Google Chrome, can detect different browsers if necessary
		let viewportWidth = window.innerWidth - scrollbarWidth;
		const styledPaddingAndPositioning = ((15+14+40)*2)

		let breakpointXsmSmCalc = () => viewportWidth - styledPaddingAndPositioning;
		let breakpointMdCalc = () => viewportWidth*0.7 - styledPaddingAndPositioning;
		let breakpointLgXlgCalc = () => (viewportWidth*0.7 - 15*2 - 14*2)*0.7 - 40*2;
		let first = window.innerWidth < 769 ? breakpointXsmSmCalc()
			: window.innerWidth < 992 ? breakpointMdCalc()
				: breakpointLgXlgCalc();
		if(this.state.sigWidth !== first || !this.state.sigWidth) {
			this.setState({
				sigWidth: first
			});
		}
	}

	/** @type Object */
	sigPad1 = null;
	clear = (sigPad) => {
		this[sigPad].clear();
	};

	getSigPad1Ref = (ref) => {
		if (!ref) return;
		this.sigPad1 = ref;
	}

	clearSigPad1Ref = () => this.clear('sigPad1')

	loadSelfAfter(){
		this.setState({warranty:false})
	}

	async handleSubmit(event, suppressToast = false) {
		if ( this.sigPad1.getCanvas() !== null) {
			this.state.signature = this.sigPad1.getCanvas().toDataURL('image/png');
		}
		this.state.name = this.state.name.trim();

		let posting = false;
		if(this.state.name !== '' && this.state.fresh_desk !== ''){
			posting = true;
		}
		await super.handleSubmit();
		if(posting){
			this.props.passedObject.post ? window.location.href = this.props.passedObject.post : ''
		}
	}

	render(){

		/** @type {Forms_Results_Properties} object */
		let {properties} = this.model;
		/** @type {Forms_Results_Object} object */
		let object = this.state;
		let isNew = !this.state.pkey || this.state.pkey === '0';
		let disabled = object.signature&&(this.props.id || this.props.id==="0")

		let sigCanvasDims = {width: this.state.sigWidth, height: 300};

		return(
			<Card>
				<CardContent>
					<Toast style={{maxWidth:"100%"}} className={"bg-warning"}>
						<ToastBody className={"text-center"}>
							<h5>WARNING: PLEASE READ CAREFULLY BEFORE SIGNING!</h5>
							<p>Karls Technology Computer Repair Service</p>
						</ToastBody>
					</Toast>
					{/*#region Information*/}
					<h4 className="section-title mdl-typography--text-center">
						Computer Repair Waiver
					</h4>

					<div className={"row"} >
						<div className={"col"}>
							<p>
								<span className={"bold"}>Impact of Upgrades: </span>
								It is the User's responsibility to understand the impact of upgrades to the operating system and applications. Some upgrades can lead to incompatibilities and possible loss of data. These issues may not become apparent until a later date. You are responsible for contacting the manufacturer regarding compatibility issues before any upgrades.
							</p>
							<p>
								<span className={"bold"}> Liability:</span> Karls Technology’s liability for damage to your computer is limited only to any damage that is determined to be caused by negligent acts or negligent omissions. Our liability for repairs is limited to the total price of repairs. Be aware that certain repairs, including but not limited to virus and malware removal, may damage software and/or data. This may require the re-installation of your operating system, applications, and/or data.
							</p>
							<p>
								<span className={"bold"}>Loss of Data: </span> Due to the process of repair, data may get damaged or lost. Karls Technology is not responsible for the loss of any data that may occur while performing work on your computer. Attempts will be made to backup data prior to attempting repairs but cannot be guaranteed.
							</p>
							<p>
								<span className={"bold"}> Ownership:</span> You must own the computer that you are having repaired.
							</p>
							<p>
								<span className={"bold"}>Privacy: </span> Karls Technology technicians will not browse through your hard drive looking for data; however, they may inadvertently see data in the course of their work. All data seen is kept strictly confidential. Please remove any personal/private files you do not want others to see.
							</p>
							<p>
								<span className={"bold"}> By signing below, I authorize Karls Technology technician(s) to perform repair work on my electronic device. I understand that Karls Technology technicians have been trained to perform work on electronic devices, but Karls Technology is not an authorized service dealer. Further, I agree to release, indemnify, and hold Karls Technology from liability for any claims or damages of any kind or description that may arise from any repair work performed on my electronic device, unless it is caused by severe negligence of Karls Technology or its agent.</span>
							</p>
						</div>
					</div>
					{/*#endregion*/}
					<fieldset disabled={!!disabled}>


					<h4 className="section-title"> Personal Information</h4>
					<div className={"row"}>
						<div className={"col-md"}>
							<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Name"}
										value={object.name}
										error={object.name_error}
										property={properties.name}
										update={this.handleTextFieldPropertyUpdate}

									/>
							</div>
						</div>

						<div className={"col-md"}>
							<div className={'form-group'}>
								<Framework.Elements.TextField
									label={"Freshdesk Number"}
									value={object.fresh_desk}
									error={object.fresh_desk_error}
									property={properties.fresh_desk}
									update={this.handleTextFieldPropertyUpdate}

								/>
							</div>
						</div>
					</div>

					<h4 className="section-title"> Signature</h4>
					<div>
						<div className={'row signature-row'} >
							<div className={'col-12'}>
								<div className="mdl-typography--text-center">
									<Card>
										<CardContent className="OnePage positionRelative">
											<span className={'signature'}/>
											{object.signature ? <img src={object.signature} alt={'Signature'}/> :
											<SignatureCanvas penColor='black' canvasProps={Object.assign({className: 'sigCanvas'}, sigCanvasDims)}
											                 ref={this.getSigPad1Ref}/>}
										</CardContent>
										{!object.signature?<CardActions className="OnePage signatureClearButtonContainer">
											<Button color="secondary" onClick={this.clearSigPad1Ref}>Clear</Button>
										</CardActions>:null}
									</Card>
								</div>
							</div>
						</div>
					</div>
					</fieldset>


				</CardContent>
				<CardActions>

					<Button color="primary" onClick={this.handleSubmit}>
						{isNew ? 'Submit' : 'Save'}
					</Button>
				</CardActions>
			</Card>

		)
	}
}
