import React from 'react';
import {Route,Switch, Redirect} from 'react-router-dom';

import Object from './components/Refund_Agreement';
import Collection from './components/Refund_Agreements';

import ErrorBoundaryRoute from '../../../../containers/ErrorBoundaryRoute';


export default ({match}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute exact path={`${match.url}/:id`}>
				<Object />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
