import React from 'react';
import {Route,Switch, Redirect} from 'react-router-dom';

import Object from './components/Mandatory_Anti_Virus_Inspection_Waiver';

import ErrorBoundaryRoute from '../../../../containers/ErrorBoundaryRoute';

export default ({match}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Object />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
