import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Breadcrumb, Objects} from "components";

export default class Computer_Repair_Waivers extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.ComputerWaivers
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>
			</div>
		);
	}
}
