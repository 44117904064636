import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';


import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from "../../constants/Types";

class NavRightList extends React.Component {

	constructor(props) {
		super(props);
		this.model = new API.BuildClass(Types.Accounts._name, API.RequestType.READ);
		this.handleLogout = this.handleLogout.bind(this);
	}

	state = {
		anchorEl: null,
	};

	handleChange = (event, value) => {
		this.props.history.push(value);
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleLogout = async(event) => {
		if( typeof event !== 'undefined' ) event.preventDefault();
		await this.model.submit(null, API.RequestType.DELETE);
		//this.props.rootState.triggerAction('logout');
		this.props.history.push('/login');
		window.location.reload();
		return false;
	}

	render() {
		const { anchorEl } = this.state;
		const {rootState} = this.props;
		const {session} = rootState;
		return (

			<ul className="list-unstyled float-right">
				<li style={{marginRight: '10px'}}>
					<Button
						aria-owns={anchorEl ? 'simple-menu' : null}
						aria-haspopup="true"
						onClick={this.handleClick}
						className="md-button header-btn"
						style={{height: '60px'}}
						>
						<span style={{
							lineHeight: '41px',
							marginRight: '10px'
						}}>{ this.props.name }</span>
						{/*<Badge badgeContent={3} color="primary">*/}
							<AppsIcon />
						{/*</Badge>*/}
					</Button>
					{session === 'Active' ?
					<Button onClick={this.handleLogout} data-id={'log-out-btn'} color={'none'} className="md-button header-btn">
						<LogOutIcon/>
					</Button> :
					<Menu
						id="simple-menu"
						open={Boolean(anchorEl)}
						onClose={this.handleClose}
						anchorEl={anchorEl}
						onChange={this.handleChange}
					>

						<MenuItem
							style={{fontSize: '14px', lineHeight: '48px'}}

						>
							<ListItemIcon >
								<i className="fas fa-sign-in-alt"></i>
							</ListItemIcon>
							<Link to={"/login"}> <ListItemText inset primary="Log In" /></Link>
						</MenuItem>
					</Menu>}
				</li>
			</ul>
		);
	}
}

export default Framework.Components.withRootState(withRouter(NavRightList));
