export const defaultState = {
	name: '',
	account: '',
	locations: [],
	locations_count: 0,
	hasPawnModule: false,
	hasSecurityModule: false,
	hasNFAModule: false,
	hasManufacturingModule: false,
	kioskMode: '',
	kioskLocation: '',
};
