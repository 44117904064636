import ObjectsObject from "components/Objects";
import CollectionsCollection from "components/Collections";
import BreadcrumbObject from "components/Breadcrumb";
import TypesObject from "constants/Types";

export let Objects = ObjectsObject;
export let Collections = CollectionsCollection
export let Breadcrumb = BreadcrumbObject;
export let Types = TypesObject;

const Components =  {
    Objects: Objects,
	Collections:Collections,
	Breadcrumb: Breadcrumb,
	Types: Types,
};

export default Components;
