import React from 'react';
import {Link} from 'react-router-dom';
import NavLeftList from './NavLeftList';
import NavRightList from './NavRightList';
import PropTypes from "prop-types";
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "constants/Config";

const FrameworkHeader = Framework.Components.withRootState(Framework.Elements.Header)

export default class Header extends React.Component {
	static propTypes = {
		name: PropTypes.string,
	};



	render() {
		const navLeft = <NavLeftList roles ={this.props.roles} name={this.props.name} history={this.props.history} reloadShell={this.props.reloadShell}
		/>

		const navRight = <NavRightList account={this.props.account} name={this.props.name} history={this.props.history} reloadShell={this.props.reloadShell} roles ={this.props.roles}
		/>

		return (
			<FrameworkHeader
				pin={false}
				shouldShowBrand={true}
				brandLink={<Link to="/" className="brand">{APPCONFIG.brand}</Link>}
				navLeft={navLeft}
				navRight={navRight}
			/>
		);
	}
}
