/** DERIVED FROM MEMCACHE */
/**
 @typedef {Object} ScannedCode
 @property {string} prefix
 @property {string} barcode
 */
/**
 @typedef {Object.<any>} Property_Generic
 @property {string} value
 @property {string} error
 @property {string} type
 @property {bool} required
 @property {bool} update
 @property {string} label
 @property {string} helper
 @property {array} set
 @property {bool} searchable
 @property {bool} queryable
 @property {bool} visible
 @property {?string} override
 @property {?string} reference
 @property {Object} range
 @property {name} pkey
 */
/**
 @typedef {Object.<any>} Accounts
 @property {Accounts_Properties} properties
 @property {Accounts_Has} has
 @property {Accounts_Is} is
 @property {Accounts_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Properties
 @property {email} email
 @property {string} password
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Accounts_Has
 */
/**
 @typedef {Object.<any>} Accounts_Is
 */
/**
 @typedef {Object.<any>} Accounts_Object
 @property {?email} email
 @property {string} email_error
 @property {?string} password
 @property {string} password_error
 @property {?number|int} pkey
 @property {string} pkey_error
 */
/**
 @typedef {Object.<any>} Customers
 @property {Customers_Properties} properties
 @property {Customers_Has} has
 @property {Customers_Is} is
 @property {Customers_Object} object
 */
/**
 @typedef {Object.<any>} Customers_Properties
 @property {text|string} email
 @property {text|string} name
 @property {text|string} phone
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Customers_Has
 */
/**
 @typedef {Object.<any>} Customers_Is
 */
/**
 @typedef {Object.<any>} Customers_Object
 @property {?text|string} email
 @property {string} email_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {?number|int} pkey
 @property {string} pkey_error
 */
/**
 @typedef {Object.<any>} Forms
 @property {Forms_Properties} properties
 @property {Forms_Has} has
 @property {Forms_Is} is
 @property {Forms_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Properties
 @property {text|string} file
 @property {text|string} model
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Forms_Has
 */
/**
 @typedef {Object.<any>} Forms_Is
 */
/**
 @typedef {Object.<any>} Forms_Object
 @property {?text|string} file
 @property {string} file_error
 @property {?text|string} model
 @property {string} model_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 */
/**
 @typedef {Object.<any>} Forms_All
 @property {Forms_All_Properties} properties
 @property {Forms_All_Has} has
 @property {Forms_All_Is} is
 @property {Forms_All_Object} object
 */
/**
 @typedef {Object.<any>} Forms_All_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {boolean} active
 @property {text|string} computer
 @property {text|string} date_signed
 @property {text|string} form_name
 @property {text|string} form_pdf
 @property {text|string} items
 @property {text|string} name
 @property {text|string} password
 @property {text|string} phone
 @property {boolean} warranty
 */
/**
 @typedef {Object.<any>} Forms_All_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_All_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_All_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?boolean} active
 @property {string} active_error
 @property {?text|string} computer
 @property {string} computer_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} form_name
 @property {string} form_name_error
 @property {?text|string} form_pdf
 @property {string} form_pdf_error
 @property {?text|string} items
 @property {string} items_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} password
 @property {string} password_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {?boolean} warranty
 @property {string} warranty_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Av_Inspection
 @property {Forms_Av_Inspection_Properties} properties
 @property {Forms_Av_Inspection_Has} has
 @property {Forms_Av_Inspection_Is} is
 @property {Forms_Av_Inspection_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Av_Inspection_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Av_Inspection_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Av_Inspection_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Av_Inspection_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Completion
 @property {Forms_Completion_Properties} properties
 @property {Forms_Completion_Has} has
 @property {Forms_Completion_Is} is
 @property {Forms_Completion_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Completion_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Completion_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Completion_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Completion_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Data_Backup
 @property {Forms_Data_Backup_Properties} properties
 @property {Forms_Data_Backup_Has} has
 @property {Forms_Data_Backup_Is} is
 @property {Forms_Data_Backup_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Data_Backup_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Data_Backup_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Data_Backup_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Data_Backup_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Deleted
 @property {Forms_Deleted_Properties} properties
 @property {Forms_Deleted_Has} has
 @property {Forms_Deleted_Is} is
 @property {Forms_Deleted_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Deleted_Properties
 @property {text|string} file
 @property {text|string} model
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Forms_Deleted_Has
 */
/**
 @typedef {Object.<any>} Forms_Deleted_Is
 */
/**
 @typedef {Object.<any>} Forms_Deleted_Object
 @property {?text|string} file
 @property {string} file_error
 @property {?text|string} model
 @property {string} model_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 */
/**
 @typedef {Object.<any>} Forms_Hdd_Inspection
 @property {Forms_Hdd_Inspection_Properties} properties
 @property {Forms_Hdd_Inspection_Has} has
 @property {Forms_Hdd_Inspection_Is} is
 @property {Forms_Hdd_Inspection_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Hdd_Inspection_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Hdd_Inspection_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Hdd_Inspection_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Hdd_Inspection_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Network_Inspection
 @property {Forms_Network_Inspection_Properties} properties
 @property {Forms_Network_Inspection_Has} has
 @property {Forms_Network_Inspection_Is} is
 @property {Forms_Network_Inspection_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Network_Inspection_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Network_Inspection_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Network_Inspection_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Network_Inspection_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Refunds
 @property {Forms_Refunds_Properties} properties
 @property {Forms_Refunds_Has} has
 @property {Forms_Refunds_Is} is
 @property {Forms_Refunds_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Refunds_Properties
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} address
 @property {text|string} date_signed
 @property {email} email
 @property {text|string} name
 @property {text|string} phone
 */
/**
 @typedef {Object.<any>} Forms_Refunds_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Refunds_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Refunds_Object
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} address
 @property {string} address_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?email} email
 @property {string} email_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Results
 @property {Forms_Results_Properties} properties
 @property {Forms_Results_Has} has
 @property {Forms_Results_Is} is
 @property {Forms_Results_Object} object
 */
/**
 @typedef {Object.<any>} Forms_Results_Properties
 @property {boolean} active
 @property {date|string} created_date
 @property {number|int} customer
 @property {number|int} form
 @property {number|int} pkey
 @property {string} results
 @property {string} signature
 @property {text|string} date_signed
 @property {text|string} fresh_desk
 @property {text|string} name
 */
/**
 @typedef {Object.<any>} Forms_Results_Has
 @property {Customers_Object[]} customers
 */
/**
 @typedef {Object.<any>} Forms_Results_Is
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
/**
 @typedef {Object.<any>} Forms_Results_Object
 @property {?boolean} active
 @property {string} active_error
 @property {?date|string} created_date
 @property {string} created_date_error
 @property {?number|int} customer
 @property {string} customer_error
 @property {?number|int} form
 @property {string} form_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} results
 @property {string} results_error
 @property {?string} signature
 @property {string} signature_error
 @property {?text|string} date_signed
 @property {string} date_signed_error
 @property {?text|string} fresh_desk
 @property {string} fresh_desk_error
 @property {?text|string} name
 @property {string} name_error
 @property {Customers_Object[]} customers
 @property {Customers_Object} customers
 @property {Forms_Object} forms
 */
const Types = {
	Accounts : {
		_name : "Accounts",
	},
	Customers : {
		_name : "Customers",
	},
	Forms : {
		_name : "Forms",
	},
	Forms_All : {
		_name : "Forms_All",
	},
	Forms_Av_Inspection : {
		_name : "Forms_Av_Inspection",
	},
	Forms_Completion : {
		_name : "Forms_Completion",
	},
	Forms_Data_Backup : {
		_name : "Forms_Data_Backup",
	},
	Forms_Deleted : {
		_name : "Forms_Deleted",
	},
	Forms_Hdd_Inspection : {
		_name : "Forms_Hdd_Inspection",
	},
	Forms_Network_Inspection : {
		_name : "Forms_Network_Inspection",
	},
	Forms_Refunds : {
		_name : "Forms_Refunds",
	},
	Forms_Results : {
		_name : "Forms_Results",
	},
};
export default Types;
