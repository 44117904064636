import React from 'react';
import {withRouter} from "react-router";

import {Button} from "reactstrap";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";

import API from "@beardeddevops/react.api";
import APPCONFIG from 'constants/Config';
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";

/**
 *  @property {Accounts_Object} state
 *  @property {object} model
 *  @property {Accounts_Properties} model.properties
 *  @property {Accounts_Is} model.is
 *  @property {Accounts_Has} model.has
 */
class Login extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Accounts._name);
		this.model.requestType = API.RequestType.READ_ALL;
		this.recaptchaRef = React.createRef();
		this.brand = APPCONFIG.brand;
		this.shouldAutoLoad = false;
		this.state.verified = false;
	}

	handleSubmit = async (event) => {
		if (typeof event !== 'undefined') event.preventDefault();
		this.setState({loading: true});
		if (this.handleProcess(null, API.RequestType.READ)) {
			await this.model.submit(this, API.RequestType.READ);
			this.props.history.push('/computer_repair_waivers');
		} else {
			this.setState({loading: false});
		}
		window.location.reload();
		return false;
	}

	handleVerify = (token) => {
		if (token.length > 0)
		{
			this.setState({verified: true})
		}
	}

	onExpired() {
	}

	onChange = () => {
	};

	render() {
		let {properties} = this.model;
		/** @type {Accounts_Object} object */
		let object = this.state;
		let brand = this.brand;

		return (
			<GoogleReCaptchaProvider reCaptchaKey={"6LfZwbcUAAAAAINSCYz4nbNni6e6mQNoaqfhK0cE"}>
				<GoogleReCaptcha onVerify={this.handleVerify}/>
				{this.state.verified && <div className="body-inner">
					<Card>
						<CardContent>
							<section className="logo text-center">
								<h1><a href="/">{brand}</a></h1>
							</section>

							<fieldset>
								<div className="form-group">
									<Framework.Elements.TextField
										value={object.email}
										error={object.email_error}
										property={properties.email}
										update={this.handleTextFieldPropertyUpdate}
										variant={"standard"}
									/>
								</div>
								<br/>
								<div className="form-group">
									<Framework.Elements.TextField
										value={object.password}
										error={object.password_error}
										property={properties.password}
										update={this.handleTextFieldPropertyUpdate}
										variant={"standard"}
										type={"password"}
									/>
								</div>
								<br/>
							</fieldset>
						</CardContent>
						<CardActions className={"d-flex justify-content-end mr-2"}>
							<Button onClick={this.handleSubmit} type={"submit"} id={"button-login"}
									style={{marginLeft: "50px"}} color="primary" size={"md"}
									block={false}>Login</Button>
						</CardActions>
					</Card>
				</div>}

			</GoogleReCaptchaProvider>

		);
	}
}

const LoginRoute = withRouter(Login);
const Page = () => (
	<div className="page-login">
		<div className="main-body">
			<div key="1">
				<LoginRoute/>
			</div>
		</div>
	</div>
);

export default Page;
