import React, {Component} from "react";
import {TrackJS} from "trackjs";
// noinspection ES6CheckImport
import PropTypes from "prop-types";

export default class ErrorBoundary extends Component {
	static propTypes = {
		isDev: PropTypes.bool.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			stack: null,
			pathname: props.location?.pathname || '',
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.location && props.location.pathname !== state.pathname) {
			return {
				hasError: false,
				pathname: props.pathname,
			}
		}

		return null;
	}

	// noinspection JSUnusedGlobalSymbols
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true
		};
	}

	componentDidCatch(error, errorInfo) {
		if (!this.props.isDev) {
			TrackJS.track(error);
			TrackJS.track(errorInfo);
			console.log(error);
			console.log(errorInfo.componentStack);
		} else {
			console.log(error);
			console.log(errorInfo.componentStack);
		}
		this.setState({
			hasError: true,
			error: error,
			stack: errorInfo && errorInfo.componentStack ? errorInfo.componentStack : null
		});
	}

	render() {
		// Whatever rendering of error messages or children components you would like
		if (this.state.hasError) {
			// You can render any custom fallback UI
			let isDev = this.props.isDev;
			let error = this.state.error;
			let stack = this.state.stack;

			let errorMessage = 'There was an error rendering the component. Support has been notified and will be looking into the issue.';
			if( this.state.error && this.state.error.hasOwnProperty('ui_message') ) {
				errorMessage = this.state.error.ui_message;
			}
			if( error && isDev ) {
				errorMessage = error.message;
			}

			return <div style={{margin: '20px'}}>
				<h3>Unexpected error has occurred.</h3>
				<p><strong><i>{errorMessage}</i></strong></p>
				{isDev && error ?
					<div>
						{ stack ?
							<div dangerouslySetInnerHTML={{__html: stack.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
							: null }
					</div>
					: null}
			</div>;
		}

		return this.props.children;
	}
}