const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
	brand: 'Karls Technology',
	year,
	copyright: '2020',
	productLink: 'https://forms.karlstechnology.com',
	reCAPTCHA: '6LfZwbcUAAAAAINSCYz4nbNni6e6mQNoaqfhK0cE',

	settings: {
		isDev: () => {
			return window.location.hostname === 'forms.beardeddevops.com' || ''
		},
		isProductionAPI: () => {
			return  APPCONFIG.apiURL !== 'https://forms.karlstechnology.com';
		},
		apiURL: window.location.hostname === 'beta.forms.karlstechnology.com' ? 'https://beta.api.forms.karlstechnology.com' : 'https://dev.api.forms.beardeddevops.com',
		apiVersion: 'v15'
	}
};

export default APPCONFIG;
