import React from "react";

import Select, {components, createFilter} from "react-select";
import FormControl from "@material-ui/core/FormControl/index";

import API from "@beardeddevops/react.api";
import HtmlTooltip from "../Tooltips/HtmlTooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import {isEqual} from 'lodash';
import MissingPropertyError from "../Errors/MissingProperty";

const {ValueContainer, Placeholder} = components;


/**
 * @property {object} props.object
 * @property {string} props.object.linked
 * @property {string} props.object.type
 * @property {string} props.object.field
 * @property {?object} props.object.object
 */
export default class _Select extends React.Component {
	static propTypes = {
		//Required
		shouldLoad: PropTypes.bool.isRequired, //Should load Data (used to prevent unnecessary server calls)
		update: PropTypes.func.isRequired,
		property: PropTypes.object.isRequired, //Link to API Property Object
		binary_id: PropTypes.any, //Needed if Server implements a Binary ID system
		value: PropTypes.any, //Can be null if values is not
		values: PropTypes.array, //Can be null if value is not
		error: PropTypes.string.isRequired,

		//Sudo Optional - More than likely needed
		object: PropTypes.any, //Current matching Object for value

		//Optional
		objects: PropTypes.array, //Pass in a list of objects to bypass server loading
		model: PropTypes.string, //Override Property Model or used for Modeless Properties such as State
		id: PropTypes.any, //Override "Pkey" as the Select Option Value
		parent: PropTypes.any, //Necessary Parent ID for parented API Calls
		text: PropTypes.string, //Override "Name" as the Select Option Text
		textHelper: PropTypes.string,
		label: PropTypes.string, //Placeholder Text to override Property Label
		nullable: PropTypes.bool, //Selected value may be Blank ("")
		default: PropTypes.object, //Default option if value is Blank ("")
		defaultFirst: PropTypes.bool, //Default selected should be the first non ""
		limit: PropTypes.any, //Override for loading limit. Null to load all
		defaultGroup: PropTypes.string,
		showToolTip: PropTypes.bool, //If passed true, doesn't show tooltip

		filter: PropTypes.func, //Used to filter the Select Objects

		groupBy: PropTypes.func,
		disabled: PropTypes.bool,
		hideLabel: PropTypes.bool,

		requestOverride: PropTypes.string, //Overrides the API READ_ALL request method

		objectKey: PropTypes.string,

		storeLastSelected: PropTypes.bool,

		groupSort: PropTypes.func,
	};

	constructor(props) {
		super(props);
		if (!this.props.property && !this.props.model) {
			throw new MissingPropertyError('Missing API property for Select. Please "Clear API Cache" from the drop down menu in the top left.');
		}
		this.modelClass = this.props.property.class || this.props.model;
		if (this.modelClass) {
			this.model = new API.BuildClass(this.modelClass, API.RequestType.READ_ALL);
			this.model.limit = typeof this.props.limit === 'undefined' ? 20 : this.props.limit; //Get up to 100 records at a time
		}

		if (this.model) {
			this.model.order = this.props.order ? this.props.order : 'asc';
			this.model.sort = this.props.sort ? this.props.sort : 'pkey';
		}

		//Initial Prop objects are added here.
		this.state = this.props.property;
		this.state.value = this.props.value;
		this.state.binary_id = this.props.binary_id;
		this.state.values = this.props.values;
		this.state.binary_ids = this.props.binary_ids || [];
		this.state.error = this.props.error;
		this.state.valueLabel = '';
		this.state.valueLabels = [];
		this.state.objects = props.objects ? props.objects : [];
		this.state.objectsChanged = false;
		this.state.total = 0;
		this.state.loading = false;

		this.state.focused = false;

		this.isMulti = typeof this.props.isMulti !== 'undefined';

		this.id = this.props.id ? this.props.id : 'pkey';
		this.binary_id = (typeof this.props.binary_id !== 'undefined') ? 'binary_id' : null;

		/** @type {Array} */
		this.optionLabel = this.props.text ? this.props.text.split('|') : ['name'];
		this.optionLabelHelper = this.props.textHelper ? this.props.textHelper.split('|') : null;

		//These options are the formatted options
		this.state.options = [];

		//Append Object passed in to the list if needed
		if (this.props.object) {
			let found = this.state.objects.find(option => {
				return this.id === 'pkey' ?
					option[this.id] === this.props.object[this.id] && (!this.binary_id || option[this.binary_id] === this.props.object[this.binary_id]) :
					option[this.id] === this.props.object[this.id]
			});
			if (!found) {
				this.state.objects = [...this.state.objects, {...this.props.object}];
			}
		}

		this.shouldReload = false;
		this.call = {};

		const {
			shouldLoad, update, property, value, values, error,
			objects, object, model, id, text, label, nullable, limit, filter,
			disabled,
			...rest
		} = props;
		if( rest.hasOwnProperty('default') ) {
			delete rest.default;
		}
		this.passThroughProps = rest;
		this._ismounted = false;

		let rand = Math.random();
		let multiplier = Math.floor(10000000);
		let newNum = rand * multiplier;
		let flat = Math.floor(newNum);
		this.customID = 'select-' + flat.toString();
		this.scrollingTo = 0;
		this.readyToScroll = false;

		this.innerRef = React.createRef();

		this.initial = true;

		this.filterFunction = filter ? filter : () => true;
	}

	currentInput = null;

	customScrolling = (e) => {
		if (!e || !e.target || !e.target.classList) {
			return;
		}

		if (e.target.classList.contains(this.customID + '__menu-list')) {
			window.clearTimeout(this.isScrolling);
			this.isScrolling = setTimeout(() => {
				const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
				if (bottom) {
					window.clearTimeout(this.isScrolling);
					this.scrollingTo = e.target.scrollTop;
					this.loadMore();
				}
			}, 500);
		}
	};

	componentDidMount() {
		this._ismounted = true;
		this.isScrolling = null;
		document.addEventListener('scroll', this.customScrolling, true);

		if (this.props.shouldLoad) {
			this.loadData(true);
		}
	}

	componentWillUnmount() {
		this._ismounted = false;
		document.removeEventListener('scroll', this.customScrolling, true);
		if (this.isScrolling !== null) {
			window.clearTimeout(this.isScrolling);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.objectKey !== prevProps.objectKey || this.props.parent !== prevProps.parent) {
			this.state.objects = this.props.objects ? this.props.objects.filter(o => this.filterFunction(o)) : [];
			this.loadData(true);
		}

		if (this.props.value !== prevProps.value || this.props.binary_id !== prevProps.binary_id) {
			this.state.value = this.props.value;
			this.state.binary_id = this.props.binary_id;
			this.setState({
				value: this.props.value,
				binary_id: this.props.binary_id,
				valueLabel: this.getCurrentLabel(),
				valueLabelHelper: this.currentLabelHelper
			});

			// Reload data if the value has an option not in state - likely means a new object was created
			if (!this.state.options.find((option) => option.label === this.props.value)) {
				this.loadData();
			}
		}

		if (!isEqual(this.props.values, prevProps.values)) {
			this.state.values = this.props.values;
			this.setState({
				values: this.props.values,
				valueLabels: this.getCurrentLabels(),
			});
		}

		if (this.props.error !== prevProps.error) {
			this.setState({error: this.props.error});
		}


		if (this.state.objectsChanged) {
			this.loadOptions();
		}

		if (!isEqual(prevProps.objects, this.props.objects) || !isEqual(prevProps.object, this.props.object)) {
			let newObjects = this.props.objects ? this.props.objects.filter(o => this.filterFunction(o)) : [];
			if (this.props.object) {
				let found = newObjects.find(option => {
					return option[this.id] === this.props.object[this.id] && (!this.binary_id || option[this.binary_id] === this.props.object[this.binary_id]);
				});
				if (!found) {
					newObjects = [...newObjects, {...this.props.object}];
				}
			}
			if (this.model) {
				this.model.limit = typeof this.props.limit === 'undefined' ? 20 : this.props.limit;
			}
			this.setState({
				objects: newObjects,
				objectsChanged: true
			});
			if (this.props.shouldLoad) {
				this.loadData();
			}
		}

		if (this.props.shouldLoad && prevProps.shouldLoad !== this.props.shouldLoad) {
			this.loadData(true);
		}

		//If we've already loaded and the value is changed externally then handle it internally
		if (prevProps.shouldLoad && prevProps.value && prevProps.value !== this.props.value) {
			//And our current inside state doesn't match the outside state.
			if (this.props.value !== this.state.value) {
				console.log('Select was updated', prevProps.value, this.props.value, this.state.value);
				this.handleSelectChange({value: this.props.value});
			}
		}
		if (prevProps.shouldLoad && prevProps.values && prevProps.values.length !== this.props.values.length) {
			console.log('Somethings Happening Here');
			this.handleSelectChange(this.props.values.map(item => {
				return {value: item};
			}));
		}

	}

	loadOptions = () => {
		let options =
			this.state.objects
				.filter(obj => this.filterFunction(obj))
				.map((option) => {
					if (this.binary_id) {
						return {
							value: option[this.binary_id] + '-' + option[this.id],
							label: this.getLabel(option),
							helper: this.getLabelHelper(option),
							original: option
						};
					} else {
						return {value: option[this.id], label: this.getLabel(option), helper: this.getLabelHelper(option), original: option};
					}
				})
				.reduce((collection, option) => {
					if( typeof this.props.groupBy === 'function' ) {
						let labelName = this.props.groupBy(option);
						let entryIndex = null;
						let entry = collection.find((entry, key) => {
							if (entry.label === labelName) {
								entryIndex = key;
								return true;
							}
							return false;
						});
						if (!entry) {
							entry = {
								label: labelName,
								options: []
							};
						}
						entry.options = [...entry.options, option];
						if (entryIndex !== null) {
							collection[entryIndex] = entry;
						} else {
							collection.push(entry);
						}
						if (this.props.groupSort && typeof this.props.groupSort === 'function')
						{
							collection.sort((group1, group2) => {
								let ranking1 = group1.options.map((option) => this.props.groupSort(option))[0]
								let ranking2 = group2.options.map((option) => this.props.groupSort(option))[0]
								return ranking1 > ranking2 ? 1 : ranking2 > ranking1 ? -1 : 0;
							})
						}
						return collection;
					}

					collection.push(option)
					return collection;
				}, []);
		//If nullable then add blank as an option
		if (this.props.nullable) {
			if( typeof this.props.groupBy === 'function' ) {
				let group = options[0];
				if( group ) {
					group.options.unshift(this.props.default && this.props.default.value === "" ? this.props.default : {
						value: "",
						label: "None"
					});
				}
				options[0] = group;
			} else {
				options.unshift(this.props.default && this.props.default.value === "" ? this.props.default : {
					value: "",
					label: "None"
				});
			}
		}
		//add default option to the top
		if (this.props.defaultGroup){
			let defaultOption = options.filter(item => item.label === this.props.defaultGroup);
			if (defaultOption.length !== 0)
			{
				options = options.filter(item => item.label !== this.props.defaultGroup);
				options.unshift(defaultOption[0]);
			}
		}
		this.setState({
			options: options,
			valueLabel: this.getCurrentLabel(),
			valueLabelHelper: this.currentLabelHelper,
			valueLabels: this.getCurrentLabels(),
			objectsChanged: false
		}, () => {
			if (this.currentInput) {
				this.innerRef.current.focus();
				// noinspection JSUnresolvedFunction
				this.currentInput.focus();
				this.currentInput = null;
			}

		});
	};

	loadData = (initial) => {
		// When logging initial for existing objects, initial is always undefined.
		// Is componentDidUpdate loadData call cancelling the componentDidMount one?
		if (this.initial && this.state.value) {
			this.initial = false;
			initial = true;
		}

		if (this.model && this.modelClass && !this.props.objects) {
			this.setState({'loading': true});
			if (this.call && typeof this.call.cancel === 'function') {
				this.call.cancel();
			}
			// noinspection JSIgnoredPromiseFromCall
			this.model.parent = typeof this.props.parent !== 'undefined' ? this.props.parent : null;

			try {
				this.model.source = 'select';
				this.model.submitCancelable(this.call, this.props.requestOverride || API.RequestType.READ_ALL, (data) => {
					this.dataLoaded(data?.items, data.total, initial);
				});
			} catch (e) {
				console.log(e);
			}
		} else if (initial && this.props.objects) {
			this.dataLoaded(this.props.objects, this.props.objects.length, initial);
		}
	};

	dataLoaded = (newObjects = [], total = 0, initial) => {
		if (this._ismounted) {
			//Add new to Existing excluding any that already exist in the list
			let stateObjects = this.state.objects.filter(o => this.filterFunction(o));
			let objects = [...stateObjects, ...newObjects.filter(o => this.filterFunction(o))].filter(
				(option, index, self) => {
					return self.findIndex(
						current => {
							if (this.binary_id) {
								return current.pkey === option.pkey && current.binary_id === option.binary_id;
							} else {
								return current.pkey === option.pkey;
							}
						}
					) === index;
				}
			);

			if( initial && this.props.storeLastSelected ) {
				//debugger;
				let previousKiosk = window.localStorage.getItem('preferred-' + this.props.property.name);
				try {
					previousKiosk = JSON.parse(previousKiosk);
					console.log(previousKiosk.pkey, this.state.value);
					if( previousKiosk.pkey !== this.state.value || (this.binary_id && previousKiosk.binary_id !== this.state.binary_id )) {
						this.props.update(this.props.property.name, previousKiosk.pkey, this.props.property.reference, previousKiosk);
					}
				} catch(e) {
				}
			}

			// Since the initial dropdown options request is paginated, there's a chance the initial
			// value for existing objects is not in the collection. This will fetch it if it's missing.
			if (initial && typeof this.state.value === 'number' && this.model) {
				let object = objects.find((option) => {
					if (this.binary_id) {
						return option.pkey === this.state.value && option.binary_id === this.state.binary_id;
					} else {
						return option.pkey === this.state.value;
					}
				});

				if (!object) {
					console.log('Need to request options from the server to get the current set one.');

					this.model.object.pkey = this.state.value;
					if (this.binary_id) {
						this.model.object.binary_id = this.state.binary_id;
					}

					try {
						//data = await this.model.submit(null, API.RequestType.READ, null, true, this.call);
						this.model.submitCancelable(this.call, API.RequestType.READ, (data) => {
							objects.push(data);
						});
						total++;
					} catch (e) {
					}
				}
			}

			this.setState({
				objects,
				objectsChanged: true,
				total,
				loading: false
			});

			//This needs to run only once, after getting parent data and after getting self data.
			if (initial === true) {
				if (this.props.defaultFirst === true && this.props.value === "" && !this.props.values) {
					let object = objects.find(option => {
						return option.value !== "";
					});
					if (object) { //Binary ID will be pulled from the object being passed back to update
						this.props.update(
							this.state.name,
							this.isMulti ? [object[this.id]] : object[this.id],
							this.props.property.reference || null,
							object
						);
					}
				}

			}

		}
	};

	loadMore = () => {
		if (this.state.objects.length !== this.state.total && !this.state.loading) {
			if (this.model) {
				this.model.limit += 20;
			}
			this.loadData(false);
		}
	};

	handleInputChange = (string, action) => {
		//If accolading then we don't need to
		if (action.action === 'input-change') {
			if (this.timeout) {
				// User typed another character in less than 500ms,
				// cancel the pending request so the main thread doesn't block while they type.
				if (typeof this.call.cancel === 'function') this.call.cancel();
				clearTimeout(this.timeout);
			}

			this.timeout = setTimeout(() => {
				//$this.customID
				//select-1827743__input
				this.currentInput = $('.' + this.customID + '__input input')[0] || null;
				if (this.model) {
					this.model.search = string;
				}
				this.loadData();
			}, 800);
		}
	};

	handleSelectChange = (selected, action) => {
		if (!this.isMulti) {
			let option = this.state.objects.filter(o => this.filterFunction(o)).filter(option => {
				if (this.binary_id) {
					return selected.value === (option[this.binary_id] + '-' + option[this.id]);
				} else {
					return selected.value === option[this.id];
				}
			});
			console.log(this.state.name,
				((option[0] ?? false) ? option[0][this.id] : selected.value),
				this.props.property.reference || null,
				option[0] || null);
			this.props.update(
				this.state.name,
				((option[0] ?? false) ? option[0][this.id] : selected.value),
				this.props.property.reference || null,
				option[0] || null
			);
			if( this.props.storeLastSelected && option[0] ) {
				//debugger;
				window.localStorage.setItem('preferred-' + this.props.property.name, JSON.stringify(option[0]));
			}
		} else {
			if (Array.isArray(selected)) {
				this.props.update(
					this.state.name,
					selected.map(item => item.value)
				);
			} else {
				if (typeof action !== 'undefined') {
					this.props.update(this.state.name, []);
				}
			}
		}
	};

	getLabel(option) {
		for (let index in this.optionLabel) {
			/** @type {Array} */
			let labelParts = [];
			let separator = ' ';
			if (this.optionLabel[index].includes('-')) {
				labelParts = this.optionLabel[index].split('-');
				separator = ' - ';
			} else if (this.optionLabel[index].includes('(')) {
				labelParts = this.optionLabel[index].split('(');
				separator = ' (';
			} else {
				labelParts = this.optionLabel[index].split('&');
			}

			let label = "";
			for (let p in labelParts) {
				let part = labelParts[p];
				if (typeof option[part] !== 'undefined' && option[part]) {
					let closeSep = false;
					if (label !== '') {
						if (separator === ' (') {
							closeSep = true;
						}
						label += separator;
					}
					label += option[part];
					if (closeSep) {
						closeSep = false;
						label += ')'
					}
				}
				if (p.toString() === '0' && label === '') {
					break;
				}
			}
			if (label !== '') {
				return label
			}
		}
		return '';
	}

	getLabelHelper(option) {
		if (this.optionLabelHelper) {
			for (let index in this.optionLabelHelper) {
				/** @type {Array} */
				let labelParts = [];
				let separator = ' ';
				if (this.optionLabelHelper.hasOwnProperty(index)) {
					if (this.optionLabelHelper[index].includes('-')) {
						labelParts = this.optionLabelHelper[index].split('-');
						separator = ' - ';
					} else if (this.optionLabelHelper[index].includes('(')) {
						labelParts = this.optionLabelHelper[index].split('(');
						separator = ' (';
					} else if (this.optionLabelHelper[index].includes('/')) {
						labelParts = this.optionLabelHelper[index].split('/');
						separator = '<br />';
					} else {
						labelParts = this.optionLabelHelper[index].split('&');
					}
				}

				let label = "";
				for (let p in labelParts) {
					let part = labelParts[p];
					if (typeof option[part] !== 'undefined' && option[part]) {
						let closeSep = false;
						if (label !== '') {
							if (separator === ' (') {
								closeSep = true;
							}
							label += separator;
						}
						label += option[part];
						if (closeSep) {
							closeSep = false;
							label += ')'
						}
					}
					// if (p.toString() === '0' && label === '') {
					// 	break;
					// }
				}
				if (label !== '') {
					return label
				}
			}
		}
		return '';
	}

	getCurrentLabel() {
		let objects = this.state.objects;

		if (typeof this.props.value === 'undefined') return '';

		if (objects && objects.length > 0) {
			if (this.state.value) {
				let current;
				if (this.binary_id) {
					current = objects.find((option) => {
						let pkeyItem = option[this.id];
						let binaryIdItem = option[this.binary_id];

						return pkeyItem && binaryIdItem && this.state.value && this.state.binary_id && pkeyItem.toString() === this.state.value.toString() && binaryIdItem.toString() === this.state.binary_id.toString();
					});
				} else {
					current = objects.find(option => option[this.id] && option[this.id].toString() === this.state.value.toString());
				}

				if (current) {
					this.currentLabelHelper = this.getLabelHelper(current);
					return this.getLabel(current);
				}
			} else {
				if (this.props.nullable) {
					return this.props.default && this.props.default.value === "" ? this.props.default.label : "";
				} else {
					if (this.props.default && this.props.default.value) {
						let current = objects.find(option => {
							if (this.binary_id) {
								return (option[this.binary_id] + '-' + option[this.id]) === this.props.default.value
							} else {
								return option[this.id] === this.props.default.value
							}
						});
						if (current) {  //Binary ID will be pulled from the object being passed back to update
							this.props.update(
								this.state.name,
								current[this.id],
								this.props.property.reference || null,
								current
							);
							this.currentLabelHelper = this.getLabelHelper(current);
							return this.getLabel(current);
						}
					}
					//Binary ID will be pulled from the object being passed back to update
					this.props.update(
						this.state.name,
						objects[0][this.id],
						this.props.property.reference || null,
						objects[0] || {}
					);
					this.currentLabelHelper = this.getLabelHelper(objects[0]);
					return this.getLabel(objects[0]);
				}
			}
		} else {
			if (this.props.nullable) {
				return this.props.default && this.props.default.value === "" ? this.props.default.label : "";
			}
		}
		return '';
	}

	getCurrentLabels() {
		let objects = this.state.objects;
		if (typeof this.props.values === 'undefined') return [''];
		if (objects && objects.length > 0) {
			if (this.state.values.length > 0) {
				let labels = [];
				for (let index in this.state.values) {
					if (this.state.values.hasOwnProperty(index)) {
						let value = this.state.values[index];
						let binary_id = this.state.binary_ids[index]; //TODO: This is a problem
						let current = null;
						if (this.binary_id) {
							current = objects.find(option => option[this.id] && option[this.binary_id] && option[this.id].toString() === value.toString() && option[this.binary_id].toString() === binary_id.toString());
						} else {
							current = objects.find(option => option[this.id] && option[this.id].toString() === value.toString());
						}

						if (current) {
							labels.push(this.getLabel(current));
						} else {
							labels.push('');
						}
					}
				}
				return labels;
			} else {
				if (this.props.nullable) {
					return this.props.default && this.props.default.value === "" ? [this.props.default.label] : [""];
				} else {
					if (this.props.default && this.props.default.value) {
						let current = objects.find(option => {
							if (this.binary_id) {
								return (option[this.binary_id] + '-' + option[this.id]) === this.props.default.value;
							} else {
								return option[this.id] === this.props.default.value;
							}
						});
						if (current) {
							this.props.update(
								this.state.name,
								[current[this.id]],
								this.props.property.reference || null,
								current
							);
							return [this.getLabel(current)];
						}
					}
					this.props.update(
						this.state.name,
						[objects[0][this.id]],
						this.props.property.reference || null,
						objects[0] || {}
					);
					return [this.getLabel(objects[0])];
				}
			}
		} else {
			if (this.props.nullable) {
				return this.props.default && this.props.default.value === "" ? [this.props.default.label] : [""];
			}
		}
		return [''];
	}

	customStyles = {
		control: (provided) => ({
			...provided,
			minHeight: 32
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: '1px 6px'
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			padding: 4,
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 3,
		})
	};

	styles = {
		...this.customStyles, ...{
			container: (provided) => ({
				...provided,
			}),
			control: (provided) => ({
				...provided,
				minHeight: '30px'
			}),
			valueContainer: (provided) => ({
				...provided,
				overflow: 'visible',
				padding: '1px 8px'
			}),
			placeholder: (provided, state) => ({
				...provided,
				position: "absolute", //(state.hasValue && state.selectProps.value.label) &&
				top: -4,
				left: -4,
				marginLeft: 0,
				marginRight: 0,
				fontSize: '1rem',
				color: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) && 'rgba(0, 0, 0, 0.54)',
				transition: "top 200ms, margin-left 200ms, background-color 200ms, font-size 200ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
				transform: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) ? "translate(14px, -6px) scale(0.75) !important" : "translate(14px, 10px) scale(1) !important",
				transformOrigin: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) && "top left",
				//transform: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) && 'matrix(0.75, 0, 0, 0.75, 14, -6)',
				backgroundColor: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) && 'white',
				padding: ((state.hasValue && state.selectProps.value.label) || state.selectProps.inputValue) && '0 8px',
			}),
			multiValue: (provided) => ({
				...provided,
				zIndex: 2
			})
		}
	};

	render() {
		let property = this.state;
		let arrayOfValues;
		if (this.isMulti) {
			if (typeof this.props.value !== 'undefined' || typeof this.props.values === 'undefined') {
				console.error('Select with name ' + this.props.property.name + ' is a multi select with a single value option');
				return null;
			}
			if (property.values === null || property.values === '') property.values = [''];
			arrayOfValues = property.values.map((value, i) => ({value: value, label: property.valueLabels[i] || 'Missing'}));
		} else {
			if (typeof property.value === 'undefined' && typeof property.values === 'undefined') {
				console.error('Select with name ' + this.props.property.name + ' is a single select with an undefined value');
				return null;
			}
			if (property.value == null) property.value = "";
		}

		let disabled = typeof this.props.disabled !== 'undefined' ? this.props.disabled : false;
		let passThroughProps = this.passThroughProps;

		return (
			<FormControl fullWidth={true} error={property.error !== ""} className={property.error !== "" ? 'error' : ''}>
				<HtmlTooltip
					placement="top"
					title={!this.props.showToolTip ?
						<React.Fragment>
							<Typography color="inherit">Start typing to search.</Typography>
						</React.Fragment> : ""}
				>
					<Select
						ref={this.innerRef}
						classNamePrefix={this.customID}
						className={'select cypress-select-id'}
						id={this.props.property.name}
						isDisabled={disabled}
						blurInputOnSelect={true}
						components={{
							ValueContainer: ({children, ...props}) =>
								<ValueContainer {...props}>
									{!this.props.hideLabel && <Placeholder {...props} isFocused={props.isFocused} className={' '}>
										{props.selectProps.placeholder}
									</Placeholder>}
									{React.Children.map(children, child => {
										return child && child.type !== Placeholder ? child : null;
									}, null)}
								</ValueContainer>,
							// MenuList: (props) =>
							// 	<components.MenuList {...props} className={this.customID}>
							// 		{props.children}
							// 	</components.MenuList>
							Option: props =>
								<div style={{borderBottom: '1px solid #e8e8e8'}}>
									<components.Option {...props}>
										<span style={{fontSize: '12px'}}>{props.data.label}</span>
										<br/>
										{props.data.helper &&
										<i style={{color: '#4e4e4e', fontSize: '10px'}} dangerouslySetInnerHTML={{__html: props.data.helper}}/>}
									</components.Option>
								</div>
						}}
						styles={this.styles}
						value={this.isMulti ? arrayOfValues : {value: property.value, label: property.valueLabel}}
						onChange={this.handleSelectChange}
						onInputChange={this.handleInputChange}
						name={property.name}
						//label={this.props.label || property.label}
						placeholder={<span>{this.props.label || property.label}</span>}
						fullWidth={true}
						options={this.state.options}
						isSearchable={true}
						isLoading={this.state.loading}
						isFocused={this.state.focused}
						//onMenuScrollToBottom={this.loadMore}
						//captureMenuScroll={true}
						filterOption={createFilter({
							ignoreCase: true,
							ignoreAccents: true,
							stringify: option => `${option.label} ${option.data.helper} ${option.value}`,
							trim: true,
							matchFromStart: false,
						})}
						{...passThroughProps}
					/>
				</HtmlTooltip>
				{
					this.state.error && <FormHelperText>{this.state.error}</FormHelperText>
				}
			</FormControl>
		)
	}
}
