import React from "react";
import {Card, CardActions, CardContent} from "@material-ui/core";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Toast, ToastBody, Button,} from 'reactstrap';

import SignatureCanvas from 'react-signature-canvas';

/**
 *  @property {Forms_Refunds_Object} state
 *  @property {object} model
 *  @property {Forms_Refunds_Properties} model.properties
 *  @property {Forms_Refunds_Is} model.is
 *  @property {Forms_Refunds_Has} model.has
 */
const setSigPadWidth = () => {
	let scrollbarWidth = 17; //Based on Google Chrome, can detect different browsers if necessary
	let viewportWidth = window.innerWidth - scrollbarWidth;
	const styledPaddingAndPositioning = ((15+14+40)*2)
	//15px: .container-fluid | 14px: .MuiCardContent_root |
	//40px: .signature (this is left/right positioning, and includes a second .MuiCardContent_root)

	let breakpointXsmSmCalc = () => viewportWidth - styledPaddingAndPositioning;
	let breakpointMdCalc = () => viewportWidth*0.7 - styledPaddingAndPositioning;
	let breakpointLgXlgCalc = () => (viewportWidth*0.7 - 15*2 - 14*2)*0.7 - 40*2;
	return window.innerWidth < 769 ? breakpointXsmSmCalc()
		: window.innerWidth < 992 ? breakpointMdCalc()
		: breakpointLgXlgCalc();
}

export default class RefundAgreement extends Framework.Components.Object{
	constructor(props) {
		super(props, Types.Forms_Refunds._name );
	}

	componentDidMount(){
		if(this.props.passedObject){
			this.setState(this.props.passedObject);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		/** @type {Forms_Refunds_Object} object */
		let object = this.state;

		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name);
		}
	}
	/** @type Object */
	sigPad1 = null;
	clear = (sigPad) => {
		this[sigPad].clear();
	};

	getSigPad1Ref = (ref) => {
		if (!ref) return;
		this.sigPad1 = ref;
	}

	clearSigPad1Ref = () => this.clear('sigPad1')

	loadSelfAfter(){
		this.setState({warranty:false})
	}

	async handleSubmit(event, suppressToast = false) {
		if ( this.sigPad1.getCanvas() !== null) {
			this.state.signature = this.sigPad1.getCanvas().toDataURL('image/png');
		}
		this.state.name = this.state.name.trim();
		//this form wasn't specifically addressed to get passed post, but we're gonna do it anyways
		let posting = false;
		if(this.state.name !== '' && this.state.fresh_desk !== '' && this.state.email !== '' && this.state.phone !== '' && this.state.address !== ''){
			posting = true;
		}
		await super.handleSubmit();
		if(posting){
			this.props.passedObject.post ? window.location.href = this.props.passedObject.post : ''
		}
	}

	render(){
		/** @type {Forms_Refunds_Properties} object */
		let {properties} = this.model;
		/** @type {Forms_Refunds_Object} object */
		let object = this.state;
		let isNew = !this.state.pkey || this.state.pkey === '0';
		let disabled = object.signature&&(this.props.id || this.props.id==="0")

		let sigCanvasDims = {width: setSigPadWidth(), height:300 };

		return(
			<Card>
				<CardContent>
					<Toast style={{maxWidth:"100%"}} className={"bg-warning"}>
						<ToastBody className={"text-center"}>
							<h5>WARNING: PLEASE READ CAREFULLY BEFORE SIGNING!</h5>
							<p>Karls Technology Refund Agreement</p>
						</ToastBody>
					</Toast>
					{/*#region Information*/}
					<h4 className="section-title mdl-typography--text-center">
						Refund Agreement
					</h4>

					<div className={"row"} >
						<div className={"col"}>
							<p>
								<span className={"bold"}>1. Refund Payment: </span>
								We will Refund or Partially Refund your payment for IT services provided by Karls Technology. The Refund Payment shall be issued to the party who originally paid via the same method you paid your original invoice.</p>
							<p>
								<span className={"bold"}> 2. Release by the Customer: </span>
								You hereby unconditionally release and forever discharge Karls Technology and our its agents, staff, representatives, managers, subsidiaries, assigns, and affiliates from any and all claims, causes of action, demands, obligations, liabilities, damages, attorneys’ fees, costs, and expenses of any type and nature whatsoever, whether in law or in equity (individually and collectively claims) whether known or unknown, either existing or that may exist from the beginning of time to the date of this release. For the avoidance of doubt, this included credit card chargebacks, credit card cancellations, credit card no authorization charges, liability shifts as well as stop payments.
							</p>
							<p>
								<span className={"bold"}>3. No Admission of Liability: </span>
								This Agreement is being entered into for the purpose of amicably and finally settling the Parties’ dispute with respect to matters described in the recitals set forth above and nothing herein shall be deemed or construed as an admission of liability by any Party with respect to such dispute or any other matter. This Agreement shall not be used or construed in connection with any other suit or other proceeding, either now pending or as may be brought, as an admission or concession of liability or otherwise on the part of any Party hereto.
							</p>
							<p>
								<span className={"bold"}> 4. Non-Disparagement: </span>
								You agree not to disparage Karls Technology or any subsidiaries in any manner (no negative feedback, no negative reviews, no negative social media posts).  For the avoidance of doubt, disparagement shall mean disparaging or otherwise making any negative statements about the products, services, members, managers, or staff of any Party. You acknowledge that a breach of this Paragraph 4 will result in irreparable harm which cannot be fully compensated by monetary damages.  Accordingly, in addition to any other remedy, which may be available to it/them, Karls Technology shall be entitled to injunctive relief in addition to the Refund Payment to address any actual or threatened violation of this Paragraph 4.
							</p>
							<p>
								<span className={"bold"}>5. Confidentiality: </span>
								You represent and agree that he/she will keep the terms of this Refund Agreement completely confidential. You further agree that he/she will not hereafter disclose any information concerning the contents of this Agreement to anyone.
							</p>
						</div>
					</div>
					{/*#endregion*/}
					<fieldset disabled={!!disabled}>


					<h4 className="section-title">Information Needed</h4>
					<div className={"row"}>
						<div className={"col-md"}>
							<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Full Legal Name"}
										value={object.name}
										error={object.name_error}
										property={properties.name}
										update={this.handleTextFieldPropertyUpdate}

									/>
							</div>
						</div>

						<div className={"col-md"}>
							<div className={'form-group'}>
								<Framework.Elements.TextField
									label={"Phone Number"}
									value={object.phone}
									error={object.phone_error}
									property={properties.phone}
									update={this.handleTextFieldPropertyUpdate}

								/>
							</div>
						</div>
					</div>
					<div className={"row"}>
						<div className={"col-md"}>
							<div className={'form-group'}>
								<Framework.Elements.TextField
									label={"Email Address"}
									value={object.email}
									error={object.email_error}
									property={properties.email}
									update={this.handleTextFieldPropertyUpdate}

								/>
							</div>
						</div>
						<div className={"col-md"}>
							<div className={'form-group'}>
								<Framework.Elements.TextField
									label={"Address"}
									value={object.address}
									error={object.address_error}
									property={properties.address}
									update={this.handleTextFieldPropertyUpdate}

								/>
							</div>
						</div>
					</div>

					<h4 className="section-title"> Signature</h4>
					<div>
						<div className={'row signature-row'} >
							<div className={'col-12'}>
								<div className="mdl-typography--text-center">
									<Card>
										<CardContent className="OnePage positionRelative">
											<span className={'signature'}/>
											{object.signature ? <img src={object.signature} alt={'Signature'}/> :
											<SignatureCanvas penColor='black' canvasProps={Object.assign({className: 'sigCanvas'}, sigCanvasDims)}
											                 ref={this.getSigPad1Ref}/>}
										</CardContent>
										{!object.signature?<CardActions className="OnePage signatureClearButtonContainer">
											<Button color="secondary" onClick={this.clearSigPad1Ref}>Clear</Button>
										</CardActions>:null}
									</Card>
								</div>
							</div>
						</div>
					</div>
					</fieldset>


				</CardContent>
				<CardActions>

					<Button color="primary" onClick={this.handleSubmit}>
						{isNew ? 'Submit' : 'Save'}
					</Button>
				</CardActions>
			</Card>

		)
	}
}
