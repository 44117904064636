import {API} from "./index";
import RequestType from "./RequestType";

export default class RestfulObject {

	className = '';
	requestType = '';

	methods = {};
	is = {};
	has = {};
	properties = {};
	object = {};

	constructor(className, requestType) {
		this.className = className;
		this.requestType = requestType;
		this.submit = this.submit.bind(this);
		this.build = this.build.bind(this);
	};

	build() {
		if (typeof API.Options[this.className] === 'undefined') {
			console.error("Missing API requested class " + this.className);
			throw new Error("Missing API requested class " + this.className);
		}
		let object = API.Options[this.className];

		/** @object object
		 * @property properties
		 * @property volatile
		 * @property methods
		 * @property has
		 * @property is
		 * @property object
		 */
		this.methods = Object.assign({}, object.methods);
		this.is = Object.assign({}, object.is);
		this.has = Object.assign({}, object.has);
		this.properties = Object.assign({}, object.properties, object.volatile);
		this.object = Object.assign({}, object.object);
	}

	getProperty(propertyName) {
		return this.properties[propertyName] ?? null;
	}

	// NOTE: _ Is a property that belongs to parent only.
	response_message = null;

	parent = null;
	parent_binary_id = null;
	grandparent = null;
	grandparent_binary_id = null;

	//File Attachments
	files = {};

	// noinspection JSUnusedGlobalSymbols
	collection = {
		loading: false,
		data: [],
		pages: null,
	};

	//Properties for Tables
	filter = [];
	search = "";
	sort = "pkey";
	order = 'desc';
	/** @type {?Number} */
	limit = 15;
	offset = 0;
	search_field = "";
	source = '';

	static get options() {
		return (async () => {
			let options = {};
			await $.ajax({
				url: API.URL + '/' + API.Version,
				type: "OPTIONS",
				success: function (result) {
					options = result;
				}
			});
			return options;
		})();
	}

	handleUXValidation(component, request, callback) {
		this.submit(component, request, callback, false, true, true, false, true).then();
	}

	// submit = async (component, request, callback, willNavigateAway, ajax, suppressToast, suppressValidation, isUXValidation) => {
	// 	// Compatibility: don't use Promise behavior if callback is passed
	// 	// if (typeof callback === 'function') {
	// 	// 	return this._submit(
	// 	// 		null,
	// 	// 		null,
	// 	// 		component,
	// 	// 		request,
	// 	// 		callback,
	// 	// 		willNavigateAway,
	// 	// 		ajax,
	// 	// 		suppressToast,
	// 	// 		suppressValidation,
	// 	// 		isUXValidation,
	// 	// 	);
	// 	// }
	// 	const hasCallback = typeof callback === 'function';
	// 	await this._submit()
	// 		.then(() => {
	//
	// 		})
	// 		.error(() => {
	// 			if (hasCallback) { throw error; }
	// 		})
	//
	// 	return new Promise((resolve, reject) => this._submit(resolve, reject, component, request, callback, willNavigateAway, ajax, suppressToast, suppressValidation, isUXValidation));
	// }

	buildRequestObject = (component, request) => {
		if (!request) {
			throw new Error("No request passed to handleSubmit.");
		}

		/**
		 * @object API.Toast
		 * @method isActive
		 * @property POSITION
		 * @property POSITION.TOP_RIGHT
		 * @property POSITION.TOP_CENTER
		 */

		if (this.properties == null) {
			API.Toast.error("Unable to retrieve API options.", {
				position: API.Toast.POSITION.TOP_CENTER,
				autoClose: 2000
			});
			throw new Error('Unable to retrieve API options.');
		}

		/**
		 * @type {Object|null}
		 * @property {number|string} pkey
		 * @property {string} binary_id
		 */
		let data = component ? component.state : this.object;
		let object = {};
		let formData = Object.keys(this.files).length > 0 ? new FormData() : null;
		let queryable = {};
		if (this.methods === null || typeof this.methods[request] === 'undefined' || this.methods[request] === null) {
			API.Toast.error("API method is not allowed.", {
				position: API.Toast.POSITION.TOP_CENTER,
				autoClose: 2000
			});
			console.log("API method is not allowed for " + request + ' on ' + this.className);
			throw new Error("API method is not allowed for " + request + ' on ' + this.className);
		}

		let url = this.methods[request];
		let idLess = url.indexOf("[ID]") === -1 && url.indexOf("[?ID]") === -1;
		if (url.indexOf("[ID]") !== -1 || url.indexOf("[?ID]") !== -1) {
			if (!data.pkey && url.indexOf("[?ID]") === -1) {
				API.Toast.error("API is missing ID for " + request + ' | ' + url, {
					position: API.Toast.POSITION.TOP_CENTER,
					autoClose: 2000
				});
				throw new Error("API is missing ID for " + request + ' | ' + url);
			}
			if (data.pkey) {
				if (data.binary_id) {
					url = url.replace('[ID]', data.binary_id + '-' + data.pkey).replace('[?ID]', data.binary_id + '-' + data.pkey);
				} else {
					url = url.replace('[ID]', data.pkey).replace('[?ID]', data.pkey);
				}
			} else if (url.indexOf("[?ID]") !== -1) {
				url = url.replace("/[?ID]", '');
			}
		}

		if (url.indexOf("[PARENT]") !== -1 || url.indexOf("[?PARENT]") !== -1) {
			if (this.parent === null && url.indexOf("[?PARENT]") === -1) {
				API.Toast.error("API is missing parent ID.", {
					position: API.Toast.POSITION.TOP_CENTER,
					autoClose: 2000
				});
				throw new Error("API is missing parent ID for " + request + ' | ' + url);
			}
			if (this.parent !== null) {
				if (this.parent_binary_id) {
					url = url.replace('[PARENT]', this.parent_binary_id + '-' + this.parent).replace('[?PARENT]', this.parent_binary_id + '-' + this.parent);
				} else {
					url = url.replace('[PARENT]', this.parent).replace('[?PARENT]', this.parent);
				}
			} else if (url.indexOf("[?PARENT]") !== -1) {
				url = url.replace("/[?PARENT]", '');
			}
		}

		if (url.indexOf("[GRANDPARENT]") !== -1) {
			if (this.grandparent === null) {
				API.Toast.error("API is missing grandparent parent ID.", {
					position: API.Toast.POSITION.TOP_CENTER,
					autoClose: 2000
				});
				throw new Error("API is missing grandparent ID for " + request + ' | ' + url);
			}
			if (this.parent_binary_id) {
				url = url.replace("[GRANDPARENT]", this.grandparent_binary_id + '-' + this.grandparent);
			} else {
				url = url.replace("[GRANDPARENT]", this.grandparent);
			}
		}

		let end = request.indexOf('_') === -1 ? request.length : request.indexOf('_');
		let type = request.substr(0, end);
		let formType = type;
		//If its multipart form-data and a PUT request then treat it like a POST.
		if (formData && type === 'PUT') {
			formType = 'POST';
		}

		if (data) {
			let files = this.files;
			this.properties.forEach(function (item, key) {
				if (typeof item !== 'undefined' && item && typeof data[key] !== 'undefined') {
					// noinspection JSUnresolvedVariable
					if ([RequestType.READ_ALL, RequestType.READ, RequestType.DELETE].indexOf(type) !== -1 && item.queryable) {
						queryable[key] = data[key];
					} else { // noinspection JSUnresolvedVariable
						if ([RequestType.CREATE, RequestType.UPDATE].indexOf(type) !== -1 && item.settable) {
							if (formData) {
								//check if data and files exist for objects that can have multiple images (otherwise they would all be "required")
								if ((item.type === 'file' || item.type === 'image') && data[key] !== null && typeof files[key] !== 'undefined') {
									formData.append(key, files[key], data[key])
								} else {
									formData.append(key, data[key])
								}
							}
							object[key] = data[key];
						}
					}
				}
			});
		}

		return {
			url: url,
			requestType: type,
			formType: formType,
			idLess: idLess,
			object: object,
			formData: formData,
			queryable: queryable,
		}
	}

	/**
	 *
	 * @param ajax This is an ajax object hat can be canceled. Pass an empty object for this parameter and it will have the cancel() method attached to it.
	 * @param request
	 * @param callback
	 * @param suppressToast
	 * @param suppressValidation
	 * @param isUXValidation
	 */
	submitCancelable(
		ajax,
		request,
		callback,
		suppressToast = false,
		suppressValidation = false,
		isUXValidation = false,
	) {
		let requestObject = this.buildRequestObject(null, request);

		this.successful = false;
		let message = {
			url: API.URL + '/' + API.Version + requestObject.url,
			type: requestObject.requestType,
			data: ((request, type, object, queryable, formData, idLess) => {
				//If GET ALL or (GET/DELETE ALTERNATIVE AND ID LESS)
				if (request === 'GET_ALL' || (request !== 'GET' && (type === 'GET' || type === 'DELETE') && idLess)) {
					return {
						filter: JSON.stringify(this.filter),
						search: this.search,
						search_field: this.search_field,
						sort: this.sort,
						order: this.order,
						limit: this.limit,
						offset: this.offset,
						source: this.source,
						object: JSON.stringify(queryable)
					}
				} else if (type === 'GET') {
					return queryable;
				} else {
					if (formData) {
						if (isUXValidation) {
							formData.append('ux_validation', 'true');
						}
						if (suppressValidation) {
							formData.append('suppress_validation', 'true');
						}
						return formData;
					} else {
						let extrasUXValidation = isUXValidation ? {ux_validation: true} : {};
						let extrasSuppressValidation = suppressValidation ? {suppress_validation: true} : {};
						return {
							object: JSON.stringify(object),
							...extrasUXValidation,
							...extrasSuppressValidation
						}
					}
				}
			})(request, requestObject.requestType, requestObject.object, requestObject.queryable, requestObject.formData, requestObject.idLess),
			contentType: requestObject.formData ? false : 'application/x-www-form-urlencoded; charset=UTF-8', //"multipart/form-data; boundary="+formData.boundary
			processData: !requestObject.formData,
			success: this.onSuccess(suppressToast, callback, requestObject.requestType),
			complete: this.onComplete(null)
		};

		let responseObject = null;
		try {
			responseObject = $.ajax(message);
		} catch (e) {
			console.log('API Request Failed and', API.shouldRetry);
			while( API.shouldRetry && API.Domains.length > 0 ) {
				console.log('Trying again and', API.shouldRetry);
				API.shouldRetry = false;
				message.url = API.URL + '/' + API.Version + requestObject.url;
				responseObject = $.ajax(message);
			}
		}
		Object(ajax).cancel = () => {
			if( responseObject && responseObject.abort && typeof responseObject.abort !== 'undefined' ) {
				responseObject.abort();
			}
		};
	}

	async submit(
		component,
		request,
		suppressToast = false,
		suppressValidation = false,
		isUXValidation = false,
		submitToAll = false,
		apiOverride = null,
	) {
		let requestObject = this.buildRequestObject(component, request);

		this.successful = false;
		let message = {
			url: (apiOverride || API.URL) + '/' + API.Version + requestObject.url,
			type: requestObject.requestType,
			data: ((request, type, object, queryable, idLess) => {
				//If GET ALL or (GET/DELETE ALTERNATIVE AND ID LESS)
				if (request === 'GET_ALL' || (request !== 'GET' && (type === 'GET' || type === 'DELETE') && idLess)) {
					return {
						filter: JSON.stringify(this.filter),
						search: this.search,
						search_field: this.search_field,
						sort: this.sort,
						order: this.order,
						limit: this.limit,
						offset: this.offset,
						source: this.source,
						object: JSON.stringify(queryable)
					}
				} else if (type === 'GET') {
					return queryable;
				} else {
					let extrasUXValidation = isUXValidation ? {ux_validation: true} : {};
					let extrasSuppressValidation = suppressValidation ? {suppress_validation: true} : {};
					return {
						object: JSON.stringify(object),
						...extrasUXValidation,
						...extrasSuppressValidation
					}
				}
			})(request, requestObject.requestType, requestObject.object, requestObject.queryable, requestObject.idLess),
			contentType: 'application/x-www-form-urlencoded; charset=UTF-8', //"multipart/form-data; boundary="+formData.boundary
			processData: true,
		};

		let result = null;
		let error = null;
		try {
			result = await $.ajax(message);
			error = null;
		} catch (e) {
			if( e && e.responseJSON && e.responseJSON.response) {
				error = e.responseJSON.response;
			} else {
				console.log('XHR error occurred', e);
				console.log('XHR error message', e.toString());
				error = e.toString();
			}
			if( !apiOverride ) {
				console.log('API Request Failed and', API.shouldRetry);
				while (API.shouldRetry && API.Domains.length > 0) {
					console.log('Trying again and', API.shouldRetry);
					API.shouldRetry = false;
					message.url = API.URL + '/' + API.Version + requestObject.url;
					try {
						result = await $.ajax(message);
						error = null;
					} catch (e) {
						error = e.toString();
					}
				}
			}
		}
		if( error !== null ) {
			throw new Error(error);
		}
		this.onSuccess(suppressToast, null, requestObject.requestType, apiOverride)(result);
		this.onComplete(component, apiOverride)({status: 200});

		if( submitToAll ) {
			//Dont both to check if they are online or have an active session.
			let OtherDomains = API.Domains.filter(domain => domain !== API.URL);
			for (let index in OtherDomains) {
				try {
					await this.submit(this, request, false, false, false, false, OtherDomains[index]);
				} catch (e) {
				}
			}
		}

		//if formData (AKA images/files) then post after other data is sent
		if ((requestObject.formType === 'POST' && requestObject.requestType === 'PUT' && requestObject.formData) ||
			requestObject.formType === 'POST' && requestObject.requestType === 'POST' && requestObject.formData) {
			//this is really only to make the IDE happy
			result = result ?? null;
			requestObject.formData.append('pkey', result.response.pkey);
			requestObject.formData.append('binary_id', result.response.binary_id);

			//dont append the id again if it is a POST since it is already apart of the url
			let additional = ''
			if(requestObject.requestType !== 'POST'){
				additional = '/' + result.response.binary_id + '-' + result.response.pkey
			}

			if (isUXValidation) {
				requestObject.formData.append('ux_validation', 'true');
			}
			if (suppressValidation) {
				requestObject.formData.append('suppress_validation', 'true');
			}
			message['url'] = message['url'] + additional;
			message['data'] = requestObject.formData;
			message['type'] = 'POST';
			message['processData'] = false;
			message['contentType'] = false;

			let results = await $.ajax(message);
			this.onSuccess(true, null, requestObject.requestType)(results);
			this.onComplete(component)({status: 200});
			return results && results.response ? results.response : null;
		} else {
			return result && result.response ? result.response : null;
		}

		//Don't throw an error here because anything but a 200 will throw an error
		//throw new Error(e.message);
	}

	onSuccess = (suppressToast, callback, type, apiOverride = null) => (result) => {
		this.successful = true;
		if (['PUT', 'POST'].includes(type) && !suppressToast && !apiOverride) {
			API.Toast.success(this.response_message ? this.response_message : (type === 'POST' ? "Updated" : "Saved"), {
				position: API.Toast.POSITION.TOP_RIGHT,
				autoClose: 2000
			});
			// if (!API.ToastId || !API.Toast.isActive(API.ToastId)) {
			// 	API.ToastId = API.Toast.success(this.response_message ? this.response_message : (type === 'POST' ? "Updated" : "Saved"), {
			// 		position: API.Toast.POSITION.TOP_RIGHT,
			// 		autoClose: 2000
			// 	});
			// }
		}
		if (typeof callback === 'function') {
			callback(result.response);
		}
	}

	/**
	 * TODO: Is willNavigateAway really needed
	 * @param component
	 * @param apiOverride
	 * @returns {function(*): void}
	 */
	onComplete = (component, apiOverride = null) => (jqXHR) => {
		if (component != null && !this.successful) {
			component.setState({loading: false});
		}
		if (jqXHR.status === 404 && !apiOverride) { //Bad Request / Bad Data  ??  This is annoying because on g
			if (component != null && component.props.base) {
				component.props.history.push(component.props.base);
			}
		}
	}

}
