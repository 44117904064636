import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import Object from './components/Mandatory_Hard_Drive_Inspection_Waiver';
import Collection from './components/Mandatory_Hard_Drive_Inspection_Waivers';
import ErrorBoundaryRoute from '../../../../containers/ErrorBoundaryRoute';

export default ({match}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute exact path={`${match.url}/:id`}>
				<Object />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
