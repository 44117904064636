import CompletionCertificate from "components/Objects/CompletionCertificate";
import ComputerRepairWaiver from "components/Objects/ComputerRepairWaiver";
import MandatoryAntiVirusInspectionWaiver from "components/Objects/MandatoryAntiVirusInspectionWaiver";
import MandatoryDataBackupWaiver from "components/Objects/MandatoryDataBackupWaiver";
import MandatoryHardDriveInspectionWaiver from "components/Objects/MandatoryHardDriveInspectionWaiver";
import MandatoryNetworkInspectionWaiver from "components/Objects/MandatoryNetworkInspectionWaiver";
import RefundAgreement from "components/Objects/RefundAgreement";

const Objects = {
	CompletionCertificate: CompletionCertificate,
	ComputerRepairWaiver: ComputerRepairWaiver,
	MandatoryAntiVirusInspectionWaiver: MandatoryAntiVirusInspectionWaiver,
	MandatoryDataBackupWaiver: MandatoryDataBackupWaiver,
	MandatoryHardDriveInspectionWaiver: MandatoryHardDriveInspectionWaiver,
	MandatoryNetworkInspectionWaiver: MandatoryNetworkInspectionWaiver,
	RefundAgreement: RefundAgreement,
};

export default Objects;
