import React from 'react';

import {Breadcrumb, Objects} from "Components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "../../../../../constants/Config";

export default class Computer_Repair_Waiver extends Framework.Components.View {
// This is the form that is populated
	render() {
		return (
			<div className="container-fluid">
				<Objects.ComputerRepairWaiver
					id={this.props.match.params.id}
					history={this.props.history}
					reloadShell={this.props.reloadShell}
					reloadPage={this.refreshPage}
					reloadSelf={this.state.refresh}
					updateName={this.updateName}
					baseUrl={'/computer_repair_waivers/form'}
					noDelete={false}
				/>
			</div>
		)
	}
};
