import React from 'react';
import Framework from '@beardeddevops/react.framework';
import SidenavContent from './SidenavContent';
import PropTypes from "prop-types";

const FrameworkSideBar = Framework.Components.withRootState(Framework.Components.SideBar);

export default class Sidebar extends React.Component {
	static propTypes = {
		locations: PropTypes.number,
		sessions: PropTypes.number,
	};

	render() {
		let props = this.props;
		return (
			<FrameworkSideBar
				footerLink={"https://karlstechnology.freshdesk.com"}
				clickOutside={false}
				{...props}
			>
				<SidenavContent {...props} />
			</FrameworkSideBar>
		);
	}
}

