import React from 'react';

import {Objects} from "Components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "../../../../../constants/Config";
import * as qs from 'query-string';

export default class Completion_Certificate extends Framework.Components.View {

	render() {
		const parsed = qs.parse(this.props.location.search);
		return (
			<div className="container-fluid">
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.CompletionCertificate
						passedObject={parsed}
						id={this.props.match.params.id}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={'/completion_certificate/form'}
						noDelete = {false}
					/>
				</Framework.Errors.General>
			</div>
		)
	}
};
