import React from "react";
import TextField from "@material-ui/core/TextField/index";
import Currency from "../Masks/Currency";
import _Base from "./_Base";
import {v4 as uuid} from "uuid";

export default class _DollarAmount extends _Base {

	constructor(props) {
		super(props);
		this.state.validation_type = 'price';
	}

	render() {
		let property = this.state;
		let passThroughProps = this.passThroughProps;
		if( property.value == null ) {
			property.value = "";
		}
		return (
			<TextField
				error={property.error !== ""}
				helperText={property.error}
				inputProps={{name: this.props.name}}
				placeholder={"0.00"}
				label={this.props.label||property.label}
				margin={this.props.margin?this.props.margin:"normal"}
				variant="outlined"
				fullWidth={true}
				InputProps={{
					startAdornment: (
						<i className="fa fa-usd" style={{marginRight: '5px'}}/>
					),
					inputComponent: Currency,
					value: property.value,
					onChange: this.handleDataChange,
					onBlur: this.handleProcess,
					onFocus: this.props.handleFocus
				}}
				{...passThroughProps}
			/>
		)
	}
}