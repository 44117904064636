import React from "react";
import PropTypes from "prop-types";
import onClickOutside from 'react-onclickoutside';

import HtmlTooltip from "../Tooltips/HtmlTooltip";

import {Button} from "reactstrap";
import {Typography, Paper} from "@material-ui/core";
import {Search, Clear} from '@material-ui/icons';
import Autosuggest from "react-autosuggest";
import {hasParentWithClass} from '../Utilities/index';

const suggestTheme = {
	container: {
		width: '100%'
	},
	input: {
		width: '100%',
		border: 'none',
		padding: '2px 4px',
		outline: 'none'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		backgroundColor: 'white',
		zIndex: '20',
		marginTop: '4px',
		borderRadius: '4px',
		marginLeft: '-4px',
		boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
	},
	suggestionsList: {
		listStyleType: 'none',
		padding: '0',
		margin: '0',
		overflow: 'hidden'
	},
	suggestion: {
		padding: '0px',
		borderBottom: '1px solid #eaeaea'
	}
};

class SuggestSearch extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		search: PropTypes.string,
		suggestions: PropTypes.array,
		onInputChange: PropTypes.func.isRequired,
		onReloadTable: PropTypes.func,
		onClearSearch: PropTypes.func,
		toolTipText: PropTypes.string,
	};

	static defaultProps = {
		highlightFirstSuggestion: true,
		focusInputOnSuggestionClick: true,
		theme: suggestTheme,
	}

	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	handleClickOutside = () => {
		// Only run the click outside handler if there are multiple instances
		if (document.querySelectorAll('.SuggestSearch').length <= 1
			|| !hasParentWithClass(this.ref?.current, 'modal-body')) {
			return;
		}

		let currentElement;

		// Sometimes react-autosuggest steals focus after a search,
		// make sure element click events outside are propagated
		if (document.activeElement.tagName === 'INPUT') {
			let hoverElements = Array.from(document.querySelectorAll(':hover'));

			if (hoverElements.length) currentElement = hoverElements[hoverElements.length - 1];

			if (currentElement && typeof currentElement.click !== 'undefined') currentElement.click();
		}
	}

	render() {
		const {
			search,
			suggestions,
			onInputChange,
			onClearSearch,
			onReloadTable,
			highlightFirstSuggestion,
			focusInputOnSuggestionClick,
			theme,
			name,
			...rest
		} = this.props;

		return (
			<div ref={this.ref} className="SuggestSearch">
				<HtmlTooltip
					placement="top"
					// passing spaced empty string allows you to pass empty and for the toolTip to not show up
					title={this.props.toolTipText !== " " ? this.props.toolTipText :
						<React.Fragment>
							<Typography color="inherit">Type anything to perform a basic
								search.</Typography>
							<em>{"Type equals"}</em> <b>{'"="'}</b> <em>to start an advance
							query</em>.<br/>
							<em>Separate the fields to search in from the text to search for with a
								colon </em> <b>{'":"'}</b><br/>
							<em>End the advance query with a semicolon</em> <b>{'";"'}</b>
						</React.Fragment>
					}
				>
					<Paper className="paper">
						<Autosuggest
							multiSection={false}
							renderSectionTitle={() => {
							}} //Not Actually Required. Just throws a dom error if not there
							getSectionItems={() => {
							}} //Not Actually Required. Just throws a dom error if not there
							suggestions={suggestions}
							highlightFirstSuggestion={highlightFirstSuggestion}
							focusInputOnSuggestionClick={focusInputOnSuggestionClick}
							theme={theme}
							inputProps={{
								value: search,
								onChange: onInputChange,
								placeholder: `Search ${name}`,
							}}
							{...rest}
						/>
						{search ?
							<Button color={'none'} size={'sm'} aria-label="Clear" onClick={onClearSearch}>
								<Clear/>
							</Button> : null}
						<Button color={'none'} size={'sm'} aria-label="Search" onClick={onReloadTable}>
							<Search/>
						</Button>
					</Paper>
				</HtmlTooltip>
			</div>
		);
	}
}

export default onClickOutside(SuggestSearch);