import React from 'react';
import {Button} from "reactstrap";

export default class Header extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			shouldShowBrand: this.props.shouldShowBrand
		}
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(this.props.shouldShowBrand !== prevProps.shouldShowBrand) {
			this.setState({shouldShowBrand: this.props.shouldShowBrand});
		}
	}

	handleSideBarToggle = () => {
		const {rootState} = this.props;
		rootState.set({hideSideBar: !rootState.hideSideBar});
	}

	handleSideBarPin = () => {
		const {rootState} = this.props;
		rootState.set({pinSideBar: !rootState.pinSideBar});
		document.getElementsByClassName('pin-sidebar-btn')[0].classList.toggle('active');
	}
	handleHeaderMenuClick = (e) => {
		let side = e.target.classList[0].split('name-')[1];
		let menu = document.querySelector(`.top-nav-${side} ul`);
		menu.style.display = 'block';

		document.addEventListener('click', function clickHandler(e2) {
			if(!menu.contains(e2.target)) menu.style.display = 'none';
			document.removeEventListener('click', clickHandler);
		})
	}

	render() {
		return(
			<section className={'app-header no-print theme-bg-color'}>
				<div className={'app-header-inner'}>
					{this.state.shouldShowBrand &&<div className={'brand'}>

						<Button onClick={this.handleSideBarToggle} className={'menu-button header-button toggle-sidebar-btn'} color={'none'}>
							<i className={'fa fa-bars'}></i>
						</Button>
						{this.props.brandLink}
						{!this.props.rootState.hideSideBar && this.props.pin &&
						<Button onClick={this.handleSideBarPin} className={'header-button pin-sidebar-btn'} color={'none'}>
							<i className={`fa fa-thumb-tack`} title={`${this.props.rootState.pinSideBar ? 'Unp':'P'}in navigation`}/>
						</Button>}
					</div>}
					<div className={'top-nav-left'}>
						{/*<a className={'mobile-name-left'} onClick={this.handleHeaderMenuClick}>
							<i className={'fa fa-bolt'}></i>
							Quick Access
						</a>*/}
						{this.props.navLeft}
					</div>
					<div className={'top-nav-right'}>
						{/*<a className={'mobile-name-right'} onClick={this.handleHeaderMenuClick}>
							<i className={'fa fa-cogs'}></i>
							Settings
						</a>*/}
						{this.props.navRight}
					</div>
				</div>
			</section>
		)
	}
}