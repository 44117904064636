import React from 'react';

const Breadcrumb = ({name, url, icon, history}) => {
	// We use history.push inside framework code because Router context breaks with duplicate instances of react-router-dom
	const handleClick = (e) => {
		e.preventDefault();
		history.push(url);
	}

	return (
		<li>
			<a href={url} onClick={handleClick}>
				<i className={`fa fa-${icon}`}/>
				<span>{name}</span>
				<i className="fa fa-angle-right"/>
			</a>
		</li>
	);
}

export default Breadcrumb;
