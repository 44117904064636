import React from 'react';
import PropTypes from "prop-types";

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

export default class UniversalModal extends React.Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		name: PropTypes.string.isRequired,
		object: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.func
		]).isRequired,
		toggle: PropTypes.bool, //Its required but can be nullable
		reportClose: PropTypes.func,

		// optionally allow parent to control visibility state
		showModal: PropTypes.bool,
		handleToggle: PropTypes.func,

		afterSave: PropTypes.func,
		afterDelete: PropTypes.func,

		size: PropTypes.string,
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		binary_id: PropTypes.string,
		parent: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		url: PropTypes.string,
		title: PropTypes.string, //Title Override
		hideGoTo: PropTypes.bool, //Hides the Go To Button

		hasSubmit: PropTypes.bool,
		submitLabel: PropTypes.string,

		hasDelete: PropTypes.bool,
		doDelete: PropTypes.func, //pass in the delete function you want to run
		deleteLabel: PropTypes.string,

		status: PropTypes.string,

		objectProps: PropTypes.object,
		redirectOnClose: PropTypes.string,
	};

	static defaultProps = {
		objectProps: {},
		status: null,
		parent: null,
		id: null,
		binary_id: null,
		hasSubmit: true,
	}

	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			save: false,
			delete: false,
			pkey: null,
			binary_id: null,
		};

		this.additionalProps = {};

		this.objectRef = React.createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//console.log(prevProps.id, this.props.id);
		if (prevProps.toggle !== this.props.toggle) {
			if (this.props.toggle === true) {
				this.toggleOpen();
			}
		}
	}

	componentWillUnmount() {
		this.objectRef = null;
	}

	toggleOpen = () => {
		if (this.props.handleToggle) {
			this.props.handleToggle();

			return;
		}

		this.setState({
			showModal: true,
			pkey: null,
			binary_id: null,
		});
	}

	toggleClose = () => {
		if (this.props.handleToggle) {
			this.props.handleToggle();

			return;
		}

		this.setState({showModal: false}, () => {
			this.props.reportClose();
		});
	}

	handleAfterSave = (data) => {
		this.toggleClose();
		if (typeof this.props.afterSave === 'function') this.props.afterSave(data);
	}
	handleAfterDelete = (data) => {
		this.toggleClose();
		if (typeof this.props.afterDelete === 'function') this.props.afterDelete(data);
	}

	updateID = (pkey, binary_id) => {
		this.setState({
			pkey,
			binary_id
		})
	}

	navigateTo = () => {
		let {name, id, binary_id, url} = this.props;
		if( this.state.pkey ) {
			id = this.state.pkey;
		}
		if( this.state.binary_id ) {
			binary_id = this.state.binary_id;
		}
		if (typeof url === 'undefined') {
			url = name + 's';
		}
		if( typeof this.props.binary_id !== 'undefined' ) {
			this.props.history.push("/" + url.toLowerCase() + "/" + binary_id + '-' + id);
		} else {
			this.props.history.push("/" + url.toLowerCase() + "/" + id);
		}
	}

	// NB: saveHandler, deleteHandler don't get called when passed to reactstrap's Button component directly.
	saveModel = () => {
		if (!this.objectRef.current.handleSubmit) {
			if( this.objectRef.current.afterSubmit ) {
				this.objectRef.current.afterSubmit.call(this.objectRef.current).then();
			}
			return;
		}

		this.objectRef.current.handleSubmit.call(this.objectRef.current);
	}

	deleteModel = () => {
		this.toggleClose();
		this.objectRef.current.handleDelete.call(this.objectRef.current);
	}

	updatedID = (pkey, binary_id) => {
		this.setState({
			pkey,
			binary_id
		});
	}

	render() {
		let {name, size, id, binary_id, hasSubmit, hideGoTo, hasDelete, doDelete, objectProps, parent} = this.props;
		let ObjectComponent = this.props.object;

		return (
			<Modal
				isOpen={this.props.showModal || this.state.showModal}
				toggle={this.toggleClose}
				size={size || "lg"}
				backdrop={true}
			>
				<ModalHeader toggle={this.toggleClose}>
					{this.props.title ? this.props.title : ((id ? 'Update ' : 'Add ') + name)}
				</ModalHeader>
				<ModalBody>
					<ObjectComponent
						ref={this.objectRef}
						{...this.additionalProps}
						history={this.props.history}
						id={id}
						binary_id={binary_id}
						parent={this.props.parent}
						afterSave={this.handleAfterSave}
						afterDelete={this.handleAfterDelete}
						hasModal={true}
						status={this.props.status}
						updateID={this.updateID}
						{...objectProps}
					/>
				</ModalBody>
				<ModalFooter>
					{/* Right aligns everything in this div*/}
					<div style={{marginRight: 'auto'}}>

						{id && !hideGoTo ?
							<a onClick={this.navigateTo} style={{marginRight: "5px"}}>
								<Button color="info">Go To</Button>
							</a> : null}

						{id && parent && hasDelete ?
							<Button
								data-id={"delete+" + name.toLowerCase().replace(/ /gi, '-')}
								color="danger"
								onClick={doDelete ?? this.deleteModel}
							>
								{this.props.deleteLabel ? this.props.deleteLabel : "Delete " + name}
							</Button>
							: null}
					</div>
					<Button color="secondary" onClick={this.toggleClose}>Cancel</Button>

					{hasSubmit ?
						<Button
							data-id={"update-add-" + name.toLowerCase().replace(/ /gi, '-')}
							color="primary"
							onClick={this.saveModel}>{this.props.submitLabel ? this.props.submitLabel : ((id ? 'Update ' : 'Add ') + name)}
						</Button> : null}{' '}
				</ModalFooter>
			</Modal>
		);
	}
}

