import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import Header from 'components/Header';
import Sidenav from 'components/Sidenav';
import Footer from 'components/Footer';

import ComputerRepairWaiver from "../routes/computer_repair_waiver/";
import ComputerRepairWaivers from "../routes/computer_repair_waivers/";
import RefundAgreement from "../routes/refund_agreement/";
import RefundAgreements from "../routes/refund_agreements/";
import CompletionCertificate from "../routes/completion_certificate/";
import CompletionCertificates from "../routes/completion_certificates/";
import MandatoryDataBackupWaiver from "../routes/mandatory_data_backup_waiver/";
import MandatoryDataBackupWaivers from "../routes/mandatory_data_backup_waivers/";
import MandatoryAntiVirusInspectionWaiver from "../routes/mandatory_anti_virus_inspection_waiver/";
import MandatoryAntiVirusInspectionWaivers from "../routes/mandatory_anti_virus_inspection_waivers/";
import MandatoryNetworkInspectionWaiver from "../routes/mandatory_network_inspection_waiver/";
import MandatoryNetworkInspectionWaivers from "../routes/mandatory_network_inspection_waivers/";
import MandatoryHardDriveInspectionWaiver from "../routes/mandatory_hard_drive_inspection_waiver/";
import MandatoryHardDriveInspectionWaivers from "../routes/mandatory_hard_drive_inspection_waivers/";
import AllWaivers from "../routes/all_waivers/";
import Login from "../routes/login";

import Framework from '@beardeddevops/react.framework';
import ErrorBoundaryRoute from "../../../containers/ErrorBoundaryRoute";


export default class MainApp extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			name: "",
			account: "",
			location: "",
			location_count: null,
			sessions_count: 0,
			session_items_count: 0,
		};
	}
	reloadShell() {
		this.props.rootState.triggerAction('getUserInformation', null);
		this.setState({reloadPage: !this.state.reloadPage});
	}

	render() {
		const {match} = this.props;
		return (
			<div className="main-app-container">
				<Sidenav history={this.props.history}/>

				<section id="page-container" className="app-page-container" >
					<Header
						name={this.state.name}
					/>
					<div className="app-content-wrapper" >
						<div className="app-content">
							<div className="full-height">
				                <Switch>

					                <ErrorBoundaryRoute path={`${match.url}all_waivers`}>
						                <AllWaivers
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}computer_repair_waiver`}>
						                <ComputerRepairWaiver
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}computer_repair_waivers`}>
										<ComputerRepairWaivers
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}refund_agreement`}>
						                <RefundAgreement
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}refund_agreements`}>
										<RefundAgreements
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}completion_certificate`}>
						                <CompletionCertificate
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}completion_certificates`}>
										<CompletionCertificates
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}mandatory_data_backup_waiver`}>
						                <MandatoryDataBackupWaiver
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}mandatory_data_backup_waivers`}>
										<MandatoryDataBackupWaivers
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}mandatory_network_inspection_waiver`}>
						                <MandatoryNetworkInspectionWaiver
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}mandatory_network_inspection_waivers`}>
										<MandatoryNetworkInspectionWaivers
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}mandatory_anti_virus_inspection_waiver`}>
						                <MandatoryAntiVirusInspectionWaiver
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}mandatory_anti_virus_inspection_waivers`}>
										<MandatoryAntiVirusInspectionWaivers
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}mandatory_hard_drive_inspection_waiver`}>
						                <MandatoryHardDriveInspectionWaiver
							                reloadShell={this.reloadShell}
						                />
					                </ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}mandatory_hard_drive_inspection_waivers`}>
										<MandatoryHardDriveInspectionWaivers
											reloadShell={this.reloadShell}
										/>
									</ErrorBoundaryRoute>

					                <ErrorBoundaryRoute path={`${match.url}login`}>
						                <Login
							            />
					                </ErrorBoundaryRoute>

					                <Redirect to="/computer_repair_waiver" />

				                </Switch>
							</div>
						</div>
						<Footer/>
					</div>
				</section>

			</div>
		);
	}
}
MainApp = Framework.Components.withRootState(MainApp);
