import {withStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

let HtmlTooltip = withStyles(theme => ({
	popper: {
		opacity: 1
	},
	tooltip: {
		backgroundColor: '#ffffff',
		color: '#4b4b4b',
		maxWidth: 360,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #E6E6E6',
		margin: '5px 0',
		'& b': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
}))(Tooltip);

export default HtmlTooltip;