import React, {Component} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';

import MainApp from 'routes/app'
import Framework from '@beardeddevops/react.framework';

import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.css';
import '@beardeddevops/react.framework/dist/index.css';

import lightTheme from './themes/lightTheme';
import 'constants/Styles.scss';

import API from "@beardeddevops/react.api";
import APPCONFIG from "constants/Config";
import Types from 'constants/Types';
import {ClipLoader} from "react-spinners";
import classNames from "classnames";

export default class App extends Component {

	constructor(props) {
		super(props);
		API.URL = APPCONFIG.settings.apiURL;
		API.Version = APPCONFIG.settings.apiVersion;
		API.setAJAXDefaults(this, toast);
		this.state = {};
		this.state.startLoading = false;
	}

	async componentDidMount() {
		const {rootState} = this.props;

		// These values can be null so rootState knows to render when these state props change,
		// or optionally in addition, a callback that fires on change. rootState.set will do nothing
		// for properties that are not declared in a subscribe call in at least one component.
		// If all components using a property unsubscribe from a property's usage, rootState doesn't set state.
		// Properties defined here not declared in root state are treated as actions that can be called via
		// rootState.triggerAction - this allows child components deep in the tree to call container methods without
		// drilling the method through components between them, e.g. reloadShell (TODO). This should result
		// in the main thread spending less CPU time invoking callbacks at each component level.
		this.rootStateID = rootState.subscribe({
			session: null
		});

		await API.loadOptions(false, APPCONFIG.settings.apiVersion);

		// Determine session state
		let something = await this.getUserInformation(this.props.location);


		// Start loading router tree
		this.setState({startLoading: true});

		this.watchID = navigator.geolocation.watchPosition((position) => {
			// Create the object to update this.state.mapRegion through the onRegionChange function
			API.Location = '';
			API.LocationGeo = position.coords.latitude + ',' + position.coords.longitude;
		}, (error) => {
		});
	}

	getUserInformation = async () => {
		const {rootState} = this.props;

		let info = new API.BuildClass(Types.Accounts._name, API.RequestType.READ);
		let data;

		try {
			data = await info.submit(null, 'GET_CHECK');
		} catch (e) {
			//throw new Framework.Errors.MissingAPIRouteError(e.message);
			return;
		}
		if( !data ) {
			return;
		}


		//If your on a locked location but logged in then redirect
		rootState.set({
			session: data.session ?? 'Not Active'
		});

		// Initialize services depending on an authenticated session
		//await this.initSessionChecks();
	}


	render() {
		let materialUITheme = lightTheme;
		return (
			<MuiThemeProvider theme={createMuiTheme(materialUITheme)}>
				<div id="app-inner">
					<div className="preloaderbar hide">
						<span className="bar"/>
					</div>
					<div className='app-main full-height fixed-header'>
						{ this.state.startLoading ?
							<Switch>
								<Route path={"/"} render={
									(props) => <MainApp {...props} //Props From Route NOT from MainApp\
									/>
								}/>
								<Redirect to="/"/>
							</Switch> :
						<div className={classNames("tabled", "loader", 'active')}>
						  <ClipLoader color={"#4A4A4A"} loading={true}/>
						</div> }
						<ToastContainer autoClose={10000} style={{textAlign: "center"}}/>
					</div>
				</div>
			</MuiThemeProvider>
		);
	}
}

App = Framework.Components.withRootState(App);

