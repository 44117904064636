import 'regenerator-runtime';

import React from 'react';
import { render } from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Framework from '@beardeddevops/react.framework';
import App from './containers/App';
import {defaultState} from 'constants/State';

const history = createBrowserHistory();

render(
	<Framework.Components.RootState defaultState = {defaultState}>
		<Router history={history}>
			<Switch>

				<Route path="/" component={App} />
			</Switch>
		</Router>
	</Framework.Components.RootState>,
	document.getElementById('app-container')
);
