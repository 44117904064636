import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Framework from "@beardeddevops/react.framework";

class SidebarContent extends React.Component {

	render() {
		const {rootState} = this.props;
		const {session} = rootState;

		return (
			<>
				{session === 'Active' &&
				<li>
					<Link variant={"text"} to={`/all_waivers`}>
						<span className="nav-text">All Waivers</span>
					</Link>
				</li>}

				<li>
					<Link variant={"text"} to={`/computer_repair_waiver`}>
						<span className="nav-text">Computer Repair Waiver</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/computer_repair_waivers">
									<span className="nav-text">All Computer Repair Waivers</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/refund_agreement">
						<span className="nav-text">Refund Agreement</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/refund_agreements">
									<span className="nav-text">All Refund Agreements</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/completion_certificate">
						<span className="nav-text">Completion Certificate</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/completion_certificates">
									<span className="nav-text">All Completion Certificates</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/mandatory_data_backup_waiver">
						<span className="nav-text">Data Backup Waiver</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/mandatory_data_backup_waivers">
									<span className="nav-text">All Data Backup Waivers</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/mandatory_hard_drive_inspection_waiver">
						<span className="nav-text">Hard Drive Inspection Waiver</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/mandatory_hard_drive_inspection_waivers">
									<span className="nav-text">All Hard Drive Inspection Waivers</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/mandatory_anti_virus_inspection_waiver">
						<span className="nav-text">Anti Virus Inspection Waiver</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/mandatory_anti_virus_inspection_waivers">
									<span className="nav-text">All Anti Virus Inspection Waivers</span>
								</Link>
							</li>}
					</ul>
				</li>

				<li>
					<Link variant={"text"} to="/mandatory_network_inspection_waiver">
						<span className="nav-text">Network Inspection Waiver</span>
					</Link>
					<ul style={{display: 'block'}}>
						{session === 'Active' &&
							<li>
								<Link variant={"text"} to="/mandatory_network_inspection_waivers">
									<span className="nav-text">All Network Inspection Waivers</span>
								</Link>
							</li>}
					</ul>
				</li>

			</>
		);
	}
}

export default Framework.Components.withRootState(withRouter(SidebarContent));
