import React from 'react';
import {Objects} from "components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "../../../../../constants/Config";
import * as qs from 'query-string';

export default class Computer_Repair_Waiver extends Framework.Components.View {
// This is the blank form.
	render() {
		const parsed = qs.parse(this.props.location.search);
		return (
			<div className="container-fluid">
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.ComputerRepairWaiver
						passedObject={parsed}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={'/computer_repair_waivers/form'}
						noDelete = {false}
					/>
				</Framework.Errors.General>
			</div>
		)
	}
};
